import {setSection} from "../../redux/reducer/sectionReducer";
import React from "react";
import {Dispatch} from "react";
import {twMerge} from "tailwind-merge";

interface StepperProps {
    formSections: Array<any>;
    section: number;
    dispatch: Dispatch<any>
}

const Stepper = ({formSections, section, dispatch}: StepperProps) => {
    return (<div className="flex gap-x-6 justify-center mb-20 container mx-auto">
        {formSections.map((step, index) => {
            const {disabled, title, icon} = step;
            return (<>
                    <button className={twMerge(`focus:outline-none group flex flex-col items-center gap-y-3 cursor-pointer ${disabled ? 'cursor-not-allowed':''} `)}
                            onClick={() => dispatch(setSection(index))} disabled={disabled}>
                        <span
                            key={'stepper'.index}


                            className={twMerge(`flex justify-center items-center focus:outline-none border-1 font-montserrat h-12 w-40 lg:w-56 rounded-lg border border-lightBlue-800  font-bold uppercase  shadow duration-300 text-lightBlue-800 bg-gray-100 group-hover:border-lightBlue-500 group-hover:bg-lightBlue-900 group-hover:text-white ${disabled ? 'text-gray-400 group-hover:text-gray-400 bg-gray-200 pointer-events-none border-gray-400 group-hover:bg-gray-200 group-hover:border-gray-400 shadow' : ''} ${section=== index ?  'bg-lightBlue-800 text-white shadow-lg':''} `)}>
                            {title}
                        </span>
                        <span
                            className={twMerge(`font-montserrat relative flex h-6 w-6 items-center justify-center rounded-full border shadow duration-300 text-lightBlue-800 bg-gray-100 group-hover:border-lightBlue-500 group-hover:bg-lightBlue-900 group-hover:text-white border-lightBlue-800 ${disabled ? 'text-gray-400 group-hover:text-gray-400 bg-gray-200 pointer-events-none border-gray-400 group-hover:bg-gray-200 group-hover:border-gray-400 shadow' : ''}  ${section=== index ?  'bg-lightBlue-800 text-white shadow-lg':''}`)}
                        >{index + 1}
                            {index < formSections.length - 1 && (
                                <span
                                    className="pointer-events-none absolute left-full z-0 block h-px lg:w-60 w-40 bg-gray-400"></span>
                            )}
                        </span>
                    </button>
                </>
            )
        })}
    </div>);
}
export default Stepper;