import {useEffect, useState} from "react";
import {faArrowLeft, faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";
import MapChart from "components/Charts/MapChart";
import SendMessage from "components/Modals/SendMessage";
import {Link, useLocation} from "react-router-dom";
import axiosClient from "api/axiosClient";

import Loading from 'components/Loadings/Loading'
import {twMerge} from "tailwind-merge";


const JobPopup = ({id = null, isPopup = false}) => {
    const [showLoader, setShowLoader] = useState(true);
    const search = useLocation().search;
    const [jobId, setJobId] = useState(id ? id : new URLSearchParams(search).get("id"));
    const [JobData, setJobData] = useState({});
    const jobOptions = useSelector(state => state.jobOptions, shallowEqual);
    const selectedJobs = (jobOptions && jobOptions.length > 0) ? jobOptions.filter(job => job.checked) : [];
    const {t, i18n} = useTranslation('site');
    const lang = i18n.language.substring(0, 2);
    const getJobDetails = () => {
        setShowLoader(true)
        axiosClient.get(`/JobManagement/GetDetailJob?jobId=${jobId}&language=${lang}&fallbackLanguage=DE`).then((response) => {

            if (response.data) {
                setJobData(response.data);
            }
            setShowLoader(false)
        })
    }


    useEffect(() => {
        getJobDetails();
    }, [search, jobId]);


    if (showLoader) return <Loading/>;
    return (
        <div className="text-blueGray-700 flex flex-col gap-y-6">
            <div>
                <h3 className="font-semibold text-sm text-blueGray-700"> {t('job_posting_title')} </h3>
                <h1 className="font-bold text-2xl text-blueGray-700">{JobData?.title}</h1>
            </div>


            <div className="relative bg-blue-50 rounded-lg p-4 shadow">
                <h2 className="font-bold text-xl">{t('key_informations')}</h2>
                <ul className="grid grid-cols-4 gap-4 text-lg">
                    <li className="flex flex-col">
                        <div className="font-bold flex gap-2 items-center">
                            <i className="fas fa-building text-sm"></i>
                            <span>
                                                {t('company_name')}
                                            </span>
                        </div>
                        <p className="pl-6">{JobData?.company?.name}</p>
                    </li>
                    <li className="flex flex-col">
                        <div className="font-bold flex gap-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                 className="bi bi-geo-alt-fill w-4 h-4" viewBox="0 0 16 16">
                                <path
                                    d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                            </svg>
                            <span>
                                                {t('place_of_work')}
                                            </span>
                        </div>
                        <p className="pl-6">{JobData?.city}</p>
                    </li>
                    <li className="flex flex-col">
                        <div className="font-bold flex gap-2 items-center">
                            <i className="fas fa-briefcase text-sm"></i>
                            <span>
                                                {t('job_type')}
                                            </span>
                        </div>
                        <p className="pl-6">{JobData?.employment}</p>
                    </li>
                    <li className="flex flex-col">
                        <div className="font-bold flex gap-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                 fill="currentColor" className="bi bi-globe w-4 h-4" viewBox="0 0 16 16">
                                <path
                                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
                            </svg>
                            <span>
                                                {t('website')}
                                            </span>
                        </div>
                        {JobData.company.url && <a href={JobData.company.url} target="_blank"
                                                   className="pl-6">{t('click_here')} <FontAwesomeIcon
                            icon={faArrowUpRightFromSquare}/></a>}
                    </li>
                </ul>

            </div>

            <div className="flex flex-col gap-y-6">
                <div
                    className="flex gap-1 flex-wrap overflow-y-auto max-h-300-px scrollable-div bg-blue-50 rounded-lg p-4 shadow ">
                    {selectedJobs.length > 0 && selectedJobs.map(job => <button key={job.target_profile_id}
                                                                                onClick={() => {
                                                                                    setJobId(job.target_profile_id);
                                                                                    setJobData(job);
                                                                                }}
                                                                                className={twMerge('focus:outline-none bg-lightBlue-800 text-white py-1 px-8 rounded-full flex gap-x-2 items-center font-semibold uppercase text-sm border border-lightBlue-800 ', job.target_profile_id !== jobId ? 'bg-white text-lightBlue-800  cursor-pointer' : '')}>
                        {job.title}
                    </button>)}
                </div>
                <div className="relative w-full bg-blue-50 rounded-lg p-4 shadow flex items-center"><h2
                    className="text-blueGray-700 text-xl font-semibold">{t('job_description')}</h2></div>
                <div className="scrollable-div overflow-y-auto text-gray-800 bg-blue-50 rounded-lg p-4 shadow ">
                    <p>
                        {JobData?.body}
                    </p>
                </div>
            </div>

            <MapChart header={t('Jobs_loaction')} data={[JobData]} cssClass="w-full" shadow={false}/>
            <SendMessage/>


        </div>
    )
}

export default JobPopup;