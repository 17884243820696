import React from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import {Radar} from 'react-chartjs-2';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Info from 'components/Modals/Info';


function BarChart({
                      data,
                      header,
                      subheader,
                      height = 100,
                      legend = false,
                      cssClass = 'w-full',
                      showButtons = false,
                      info = ""
                  }) {

    const {t} = useTranslation('site');
    ChartJS.register(
        RadialLinearScale,
        PointElement,
        LineElement,
        Filler,
        Tooltip,
        Legend
    );


    const options = {

        responsive: true,
        scales: {
            r: {
                pointLabels: {
                    font: {
                        size: 12
                    }
                },
                suggestedMax: 100
            }
        },
        plugins: {
            legend: {
                position: 'top',
                display: legend
            },
            title: {
                display: false,
                text: '',
            },
        },
    };

    const RadarData = {
        labels: data[0].data.map((d) => d.sub_category_name),
        datasets: data.map((d) => {
            return {
                label: d.sub_category_name,
                data: d.data.map((elm) => elm.ranking_score),
                backgroundColor: d.backgroundColor,
            };
        }),
    }
    return (
        <div className={cssClass}>
            <div className="flex flex-col gap-y-6 w-full">


                <div className="flex flex-wrap items-center">
                    <div className="relative w-full bg-blue-50 rounded-lg p-4 shadow flex items-center">
                        {info && <Info cssClasses="absolute right-2" msg={info}/>}
                        {subheader && <h6 className="uppercase text-blueGray-400 text-xs font-semibold">
                            {subheader}
                        </h6>}
                        {header && <h2 className="text-blueGray-700 text-xl font-semibold">
                            {header}
                        </h2>}
                    </div>
                    {showButtons && <div className="flex ">
                        <div className="flex flex-col px-2">
                            <button
                                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-2 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5">{t('cv_job_mapping')}</button>
                        </div>
                        <div className="flex flex-col px-2">
                            <Link
                                className="bg-lightBlue-500 text-white text-center active:bg-lightBlue-600 font-bold uppercase text-sm px-2 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5"
                                to="/boost-training">{t('BOOST_BY_TRAINING_Btn')}</Link>
                        </div>
                        <div className="flex flex-col px-2">
                            <Link
                                className="bg-lightBlue-500 text-white text-center active:bg-lightBlue-600 font-bold uppercase text-sm px-2 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5"
                                to="/boost-career"> {t('BOOST_CAREER_Btn')}</Link>
                        </div>
                    </div>}
                </div>
                <div className=" bg-blue-50 rounded-lg p-4 shadow">
                    <Radar height={height} data={RadarData} options={options}/>
                </div>
            </div>
        </div>
    );
}

export default BarChart;