/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import dynaskillsLogo from '../../assets/img/dynaskillsuprising.png';
import Loading from "components/Loadings/Loading";
import Button from "../../components/Buttons/Button";
import SpinLoading from "../../components/Loadings/SpinLoading";

export default function ResetPassword() {
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);


    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token");
    const userId = new URLSearchParams(search).get("id");

    async function resetPassword(e) {
        setShowLoader(true);
        setErrorMessage("");
        e.preventDefault();
        //const url = 'https://ukranian-sunrise-server.herokuapp.com/api/login';
        const url = process.env.REACT_APP_BACKEND_DYNASKILLS + '/resetPassword';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId,
                password,
                token
            })
        });
        const data = await response.json();
        if (response.status === 500) {
            setErrorMessage(data.error);
            setShowLoader(false);

        }

        if (response.status === 200) {
            setShowSuccess(true);
            setShowLoader(false);

        }
    }


    return (
        <>
            <div className="container mx-auto px-4 h-full flex flex-grow justify-center flex-col">
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-1/2 mx-auto">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 border-0">
                            <div className="flex justify-center mb-10">
                                <img src={dynaskillsLogo} alt="Ukranian Sunrise" className="ml-10"
                                     style={{width: "280px", height: "auto"}}/>
                            </div>
                            <div className="text-center mb-10">
                                <h6 className="font-semibold text-4xl">
                                    Reset your password
                                </h6>

                            </div>
                            <div className="shadow-md bg-gray-50 rounded-xl p-12 gap-4 flex flex-col relative">
                                {showSuccess ?
                                    <div className="bg-green-400 mb-5 px-4 text-center text-lg py-5 rounded text-white">
                                        <p>Password Reset Successfully!</p>
                                        <Link to="/auth/login"
                                              className="text-center bg-white text-lg font-semibold text-green-400 block mt-5 py-3 px-3 rounded"> Go
                                            to Login page</Link>
                                    </div> : <form onSubmit={resetPassword}>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block capitalize text-xs font-bold"
                                                htmlFor="grid-password"
                                            >
                                                Password
                                            </label>
                                            <input
                                                type="password"
                                                className="text-left focus:outline-none block w-full relative py-2 px-4 cursor-pointer text-gray-500 bg-white rounded-md border border-blueGray-200"
                                                onChange={(e) => setPassword(e.target.value)}
                                                value={password}
                                            />
                                        </div>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block capitalize text-xs font-bold"
                                                htmlFor="grid-password"
                                            >
                                                Re-type Password
                                            </label>
                                            <input
                                                type="password"
                                                className="text-left focus:outline-none block w-full relative py-2 px-4 cursor-pointer text-gray-500 bg-white rounded-md border border-blueGray-200"
                                                onChange={(e) => setRepeatPassword(e.target.value)}
                                                value={repeatPassword}
                                            />
                                        </div>


                                        <div className="text-center mt-6">
                                            <Button type="submit" className="flex gap-x-2"
                                                    right>Reset your password{showLoader &&
                                                <SpinLoading color="white" insideBtn/>}
                                            </Button>
                                            <div className="text-red-500">{errorMessage}</div>


                                        </div>

                                    </form>}

                            </div>
                        </div>
                        <div className="flex flex-wrap mt-6 relative">
                            <div className="w-1/2 text-right">
                                <Link to="/auth/register" className="text-blueGray-200">
                                    <small>Create new account</small>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
