import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import BarChart from "../../components/Charts/HorizontalBarChartRestriction";
import RestrictionDetailsModal from "../../components/Modals/RestrictionDetailsModal";
import {CSSProperties} from "react";

const SelectLevel = ({id, defaultValue, title, setAnswers, actionName, selectedValue, setEnableButtons, disabled=false}) => {
    const [level, setLevel] = useState(selectedValue);
    const onChangeHandler = (value) => {
        setEnableButtons(false);
        setLevel(parseInt(value))
        setAnswers((prevState) => {
            const newItem = {
                id,
                restriction: `${actionName} ${value} - ${title}`,
                value
            };

            return [...prevState.filter((item) => item.id !== id), newItem];
        })
    }

    const percentValue = useMemo(() => {
        return (level * 100) / 5;
    }, [level]);
    const inputStyle: CSSProperties = useMemo(() => {
        return {'--percent': `${percentValue}%`};
    }, [percentValue]);

    return (<>
            <div className="w-40 h-9 flex flex-col justify-between">
                <div className="w-40 px-2">
                    <div className="relative">
                    <span
                        className="select-none absolute top-0 py-1 px-3 rounded font-semibold text-xs leading-none uppercase bg-gray-300 text-white -translate-x-1/2"
                        style={{left: `calc(${percentValue}% - 13px)`}}
                    >
                      {level}
                    </span>
                    </div>
                </div>
                <labe><input onChange={(e) => onChangeHandler(e.target.value)} style={inputStyle} type="range" min="0"
                             max="5" step="1" value={level}
                             className="input-range w-40 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" disabled={disabled}/>
                </labe>
            </div>
        </>
    )
}
const transformValue = (value) => {
    switch (Number(value)) {
        case 1:
            return '13';
        case 2:
            return '21';
        case 3:
            return '34';
        case 4:
            return '55'
        case 5:
            return '89';
        default:
            return 0;
    }
}
const transformValues = (answers) => {
    if (!answers) {
        return [];
    }
    return answers.map((item) => {
        const updatedValue = transformValue(item.value);
        return {
            ...item,
            value: updatedValue
        }
    })
}
const CvHealthSection = ({
                             sectionTitle,
                             restrictions,
                             setAnswers,
                             answers,
                             actionName = 'SetCV',
                             defaultValue = 5,
                             oppositeAnswers,
                             mainColor,
                             secondColor,
                             setEnableButtons,
                             disabled=false
                         }) => {

    const {t} = useTranslation('restrictions');
    const [isOpen, setIsOpen] = useState(false);
    const sectionAnswers = answers.filter((answer) => restrictions.some((item) => item.id === answer.id));
    // const sectionOppositeAnswers = oppositeAnswers.filter((answer)=> restrictions.some((item) => item.id === answer.id));
    const sectionOppositeAnswers = sectionAnswers.map((answer) => {
        const oppositeAnswer = oppositeAnswers.find((item) => item.id === answer.id);
        if (!oppositeAnswer) {
            return {...answer, value: defaultValue === 5 ? 0 : 5};
        }
        return oppositeAnswer;
    });
    const transformedSectionAnswers = transformValues(sectionAnswers);
    const transformedSectionOppositeAnswers = transformValues(sectionOppositeAnswers);

    const answersData = {
        label: defaultValue === 5 ? 'cv' : 'job',
        data: transformedSectionAnswers,
        backgroundColor: mainColor
    }

    const answersOppositeData = {
        label: defaultValue === 5 ? 'job' : 'cv',
        data: transformedSectionOppositeAnswers,
        backgroundColor: secondColor
    }

    return (
        <div>
            <div
                className="relative cursor-pointer flex justify-between items-center w-full bg-blue-50 rounded-lg p-4 shadow z-10"
                onClick={() => setIsOpen(!isOpen)}>
                <span className="text-xl font-semibold">{t(sectionTitle)}</span>
                <i className={`fa text-xl ${isOpen ? "fa-chevron-down" : "fa-chevron-up"}`}></i>
            </div>
            {isOpen && (
                <>
                    <div className="bg-white z-0 p-8 shadow-md flex flex-col gap-2">
                        {restrictions.map((restriction) => {
                            const selectedValue = answers.find(item => item.id === restriction.id);
                            return (
                                <div key={restriction.id} className="flex justify-between items-center ">
                                    <span className="text-lg">
                                        {t(restriction.title)} <RestrictionDetailsModal msg={t(restriction.info)}/>
                                    </span>
                                    <SelectLevel id={restriction.id} defaultValue={defaultValue}
                                                 title={t(restriction.title)} setAnswers={setAnswers}
                                                 actionName={actionName}
                                                 selectedValue={selectedValue ? selectedValue.value : defaultValue}
                                                 setEnableButtons={setEnableButtons}
                                                 disabled={disabled}
                                                />
                                </div>
                            )
                        })}
                    </div>
                    <BarChart cssClass="w-full" data={[answersData, answersOppositeData]} height={100}
                              showButtons={false} info="top_cv_job_qualifications_info"/>
                </>
            )}
        </div>
    );
}

export default CvHealthSection;