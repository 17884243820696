/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import dynaskillsLogo from '../../assets/img/dynaskillsuprising.png'
import {addCvList} from "redux/reducer/cvListReducer";
import axios from "axios";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import Toggle from "../../components/Buttons/Toggle";
import Button from "../../components/Buttons/Button";
import SpinLoading from "../../components/Loadings/SpinLoading";

export default function Login() {
    const {t, i18n} = useTranslation('site');
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();

    async function loginUser(e) {
        setErrorMessage("");
        setShowLoader(true);
        e.preventDefault();
        const url = process.env.REACT_APP_BACKEND_DYNASKILLS + '/login';
        await axios.post(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                username: email,
                password
            }
        }).then(response => {
            const data = response.data;
            if (data.user) {

                localStorage.setItem('token', data.token)
                localStorage.setItem('authorisation', data.user.authorization);
                localStorage.setItem('username', data.user.username);
                localStorage.setItem('email', data.user.email);
                localStorage.setItem('premium', data.user.premium);
                /*if (data.user.cvs.length === 0) {
                  localStorage.setItem('show_add_cv', true);
                }
                dispatch(addCvList(data.user.cvs))*/
                // if (data.user.company) {
                //   if (data.user.cvs.length === 0) {
                //     localStorage.setItem('show_add_cv', true);
                //   }
                //   dispatch(addCvList(data.user.cvs));
                //   window.location.href = '/matching-jobs'
                // } else {
                getCvList(data.user.authorization);
                // }

                //setShowLoader(false);

                //getCvList(data.user.authorization)
                //window.location.href = '/matching-jobs'
                //history.push("/matching-jobs");

            }
        }).catch(err => {
            console.log(err.response);
            setErrorMessage(t(err?.response?.data?.error));
        }).finally(() => {
            setShowLoader(false);
        });


    }

    async function getCvList(authorisation) {
        let cvList = [];
        await axios.get('https://mct-demo.dynajobs.com/api/CVManagement/GetUploadedCVs', {
            headers: {
                'Authorization': 'DYNAService ' + authorisation
            }
        }).then(function (response) {
            if (response.data && response.data.length > 0) {
                cvList = response.data.map(data => {
                    return {
                        profile_id: data.cv_id,
                        title: data.details.title
                    }

                })

            }

            dispatch(addCvList(cvList))
            window.location.href = '/matching-jobs'

        }).finally(function () {
            setShowLoader(false);
        });

    }

    const changeLanguageHandler = (e) => {
        i18n.changeLanguage(e.target.value)
    }

    return (
        <>

            <div className="container mx-auto px-4 h-full flex flex-grow justify-center flex-col">
                <div className="relative flex content-center items-center justify-center h-full">
                    <div className="w-1/2 mx-auto">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 border-0">
                            <div className="flex justify-center mb-10">
                                <img src={dynaskillsLogo} alt="Ukranian Sunrise" className="ml-10 filter grayscale"
                                     style={{width: "280px", height: "auto"}}/>
                            </div>
                            <div className="text-center mb-10">
                                <h6 className="font-semibold text-4xl">
                                    {t('sign_in_btn')}
                                </h6>

                            </div>
                            <div className="shadow-md bg-gray-50 rounded-xl p-12 gap-4 flex flex-col relative">

                                <form onSubmit={loginUser}>
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block capitalize text-xs font-bold"
                                            htmlFor="grid-password"

                                        >
                                            {t('username')}
                                        </label>
                                        <input
                                            type="text"
                                            className="text-left focus:outline-none block w-full relative py-2 px-4 cursor-pointer text-gray-500 bg-white rounded-md border border-blueGray-200"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                        />
                                    </div>

                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block capitalize text-xs font-bold"
                                            htmlFor="grid-password"
                                        >
                                            {t('password')}
                                        </label>
                                        <input
                                            type="password"
                                            className="text-left focus:outline-none block w-full relative py-2 px-4 cursor-pointer text-gray-500 bg-white rounded-md border border-blueGray-200"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                        />
                                    </div>
                                    <div>
                                        <label className="inline-flex items-center cursor-pointer gap-x-2">
                                            <Toggle id="customCheckLogin" onChange={(e)=> {
                                            }}/>
                                            <span className="block capitalize text-xs font-bold select-none">
                                                {t('remember_me')}
                                            </span>
                                        </label>
                                    </div>

                                    <div className="text-center mt-6">

                                        <Button type="submit" className="flex gap-x-2"
                                                right>{t('sign_in_btn')}{showLoader &&
                                            <SpinLoading color="white" insideBtn/>}
                                        </Button>
                                        <p className="text-red-500">{errorMessage}</p>
                                    </div>
                                </form>
                                <div className="flex justify-between mt-10">
                                    <div className="flex items-center gap-x-2">
                                        {t('no_account_already')}<Link to="/auth/register"><p className="text-lightBlue-800 underline">{t('sign_up')}</p></Link>
                                    </div>
                                    <a
                                        className="text-gray-500"
                                        href="/auth/forget-password"
                                        rel="noreferrer"
                                    >
                                        {t('forgot_password')}

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
