import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Info from 'components/Modals/Info';
import {twMerge} from "tailwind-merge";


  
const BarChart = ({ data, header, subheader, height = 200, legend = false, stacked = false, cssClass = "w-full", showButtons = false, info="", restrictionButtonUrl="", restrictionTitle,type })=> {
  const { t } = useTranslation('site');
  
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );

      const options = {
        indexAxis: 'y' ,
        elements: {
          bar: {
            borderWidth: 1,
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            position: 'top' ,
            display: legend
          },
          title: {
            display: false,
            text: '',
          },
        },
        scales: stacked && {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
      }
      };

    const BarData = {
        labels: data[0].data.map((d) => d.sub_category_name),
        datasets: data.map((d) => {
            return {
                label: d.label,
                data: d.data.map((elm) => elm.ranking_score),
                backgroundColor: d.backgroundColor,

            };
        }),
    }
  return (
    
    <div className={cssClass}>
      {restrictionButtonUrl && <div className='pb-4 flex gap-2 items-center'>
        <Link to={restrictionButtonUrl}
              className={twMerge('cursor-pointer border text-white relative items-center text-center font-semibold uppercase text-sm py-1 px-8 rounded-full shadow-sm hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150',
              type === 'cv' ? 'bg-lightBlue-800 hover:bg-lightBlue-900' : 'bg-green-600 hover:bg-green-700',
              )}>
            <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" className="absolute left-2 top-1/2 h-5 -translate-y-1/2 transform" viewBox="0 0 16 16">
                <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
                <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
            </svg>
          {restrictionTitle}
        </Link>
        <Info cssClasses="absolute right-6 top-0" msg={t('CV/Job Health restrictions info')} />
      </div>}
      <div className="relative flex flex-col bg-gray-50 rounded-xl px-4 py-5 shadow-md ">
        <div className="rounded-t mb-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-lightBlue-800 mb-1 text-xs font-bold">
              {subheader}
              </h6>
              <h2 className="text-blueGray-700 text-xl font-semibold">
              {header}
              </h2>
              {info && <Info cssClasses="absolute right-2 top-0" msg={info} />}
            </div>
            {showButtons && <div className="flex ">
              <div className="flex flex-col px-2">
                  <Link className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-2 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5" to="matching-jobs">{t('cv_job_mapping')}</Link>
              </div>
              <div className="flex flex-col px-2">
                <Link className="bg-lightBlue-500 text-white text-center active:bg-lightBlue-600 font-bold uppercase text-sm px-2 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5" to="/boost-training">{t('BOOST_BY_TRAINING_Btn')}</Link>
              </div>
              <div className="flex flex-col px-2">
                <Link className="bg-lightBlue-500 text-white text-center active:bg-lightBlue-600 font-bold uppercase text-sm px-2 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5" to="/boost-career"> { t('BOOST_CAREER_Btn')}</Link>
              </div>
            </div>}
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative">
            <Bar height={height} options={options} data={BarData} className="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BarChart;