import {twMerge} from "tailwind-merge";
import React, {useEffect, useRef, useState} from "react";

interface DropdownJobsProps {
    list: [];
    selected?: any;
    setSelected: () => void;
}

const DropdownJobs = ({list, setSelected, selected}: DropdownJobsProps) => {
    const [open, setOpen] = useState(false);
    const btnRef = useRef(null);
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && btnRef.current && !btnRef.current.contains(event.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);
    return (
        <div className="relative">
            <button ref={btnRef}
                    onClick={() => setOpen(!open)}
                    className={twMerge(`text-left focus:outline-none block w-full relative py-2 px-4 cursor-pointer text-gray-500 bg-white rounded-md border border-blueGray-200 ${open ? 'rounded-b-none' : ''}`)}
            >
                {selected ? selected.title : 'Select job'}
                {open ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="absolute top-1/2 h-5 -translate-y-1/2 transform right-4" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="absolute top-1/2 h-5 -translate-y-1/2 transform right-4" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                )}
            </button>

            {open && <div ref={dropdownRef}
                          className=" -mt-px border border-blueGray-200 z-1 w-full bg-white absolute max-h-300-px overflow-x-auto  rounded-b-md shadow-lg scrollable-div">
                {list && list.length > 0 && list.map((job, index) => <div className="flex hover:bg-gray-100">
                    <button
                        className={twMerge("focus:outline-none w-full text-left flex justify-between text-gray-600 cursor-pointer py-2 px-4 hover:font-semibold hover:text-gray-900 hover:bg-gray-100",
                            selected && selected.target_profile_id === job.target_profile_id && 'font-semibold text-gray-900 bg-gray-100'
                        )}
                        key={index} onClick={() => {
                        setOpen(false);
                        setSelected(job);
                    }}>{job.title} </button>
                </div>)}
            </div>}
        </div>
    );
};

export default DropdownJobs;
