import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


const OrCreateCV = () => {
    const { t } = useTranslation('site');
    return <div className="justify-self-end">
        <p className="text-center text-2xl font-light leading-10 hidden">{t('create_new_cv')}?</p>
        <div className="flex items-center justify-end gap-3">
            <p className="text-xl">{t('or')}</p>
            <Link
                className={`block text-center bg-lightBlue-500 text-white active:bg-lightBlue-900 font-semibold uppercase text-sm  py-1 px-3  rounded-full shadow-sm hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 `}
                to="/cv"
            >
                {t('create_new_cv')}
            </Link>
        </div>
    </div>
}

export default OrCreateCV;