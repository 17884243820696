import React from 'react'
import HeaderStats from "components/Headers/HeaderStats.js";
import HorizontalBarChart from "components/Charts/HorizontalBarChart.js";
import RadarChart from "components/Charts/RadarChart.js";
import {useDispatch, useSelector} from "react-redux";
import Mapper from '../../utils/mapper';
import MapperPercentage from '../../utils/mapperPercentage';
import trainingMapper from 'utils/trainingMapper';
import MapChart from 'components/Charts/MapChart';
import {useTranslation} from 'react-i18next';
import translateRubrications from '../../utils/translateRubrications';
import ActualQualifications from './ActualQualifications.js';
import {setSection} from "redux/reducer/sectionReducer";
import {twMerge} from "tailwind-merge";


function MatchingJobsCV1Section() {
    const {t, i18n} = useTranslation('site');
    const lang = i18n.language.substring(0, 2);
    let {
        cvId,
        jobId,
        resultNumber,
        cvRubricationData,
        jobRubricationData,
        trainingRubricationData
    } = useSelector(state => state.searchInput);

    cvRubricationData = translateRubrications(cvRubricationData, lang);
    jobRubricationData = translateRubrications(jobRubricationData, lang);
    trainingRubricationData = translateRubrications(trainingRubricationData, lang);

    const jobList = useSelector(state => state.jobList);
    const jobs = jobList?.find(j => j.cvId === cvId);
    const dataJobList = jobId ? jobs?.jobList : [];

    const updatedCvs = useSelector(state => state.updatedCvs);
    const updatedCv = updatedCvs.find(d => d.cvId === cvId && d.jobId === jobId);
    const updatedCvRubrications = updatedCv ? updatedCv.cvRubrications : [];

    const sortedCvRubrications = cvRubricationData.sort((a, b) => b.ranking_score - a.ranking_score);

    const cvQualifications = resultNumber > 0 ? sortedCvRubrications.slice(0, 10) : sortedCvRubrications;

    const cvQualificationsData = {
        label: "",
        data: cvQualifications.map(el => {
            if (!el.checked) {
                return {
                    ...el,
                    ranking_score: 0
                }
            }
            return el;
        }),
        backgroundColor: '#075985'
    }

    const unCheckdCvQualificationsData = {
        label: "",
        data: cvQualifications.map(el => {
            if (el.checked) {
                return {
                    ...el,
                    ranking_score: 0
                }
            }
            return el;
        }),
        backgroundColor: '#fecaca'
    }

    const cvQualificationsHeader = resultNumber > 0 ? t('TOP_10 _CV_qualifications') : t('all_cv_qualifications');


    const sortedJobRubrications = jobRubricationData.sort((a, b) => b.ranking_score - a.ranking_score);
    const jobQualifications = resultNumber > 0 ? sortedJobRubrications.slice(0, 10) : sortedJobRubrications;
    const jobQualificationsData = {
        label: "",
        data: jobQualifications.map(el => {
            if (!el.checked) {
                return {
                    ...el,
                    ranking_score: 0
                }
            }
            return el;
        }),
        backgroundColor: '#16a34a'
    }
    const unCheckdJobQualificationsData = {
        label: "",
        data: jobQualifications.map(el => {
            if (el.checked) {
                return {
                    ...el,
                    ranking_score: 0
                }
            }
            return el;
        }),
        backgroundColor: '#fecaca'
    }

    const jobQualificationsHeader = resultNumber > 0 ? t('top_10_job_qualifications') : t('all_job_qualifications');

    const cssClass = jobRubricationData.length > 0 ? 'w-full xl:w-6/12 mb-12 xl:mb-0 mx-auto' : 'w-full';


    const height = resultNumber === '' ? 250 : 200;
    const jobCvMapping = Mapper(jobQualifications, cvRubricationData);
    const updatedJobCvMapping = Mapper(jobQualifications, updatedCvRubrications);
    const jobMappingData = {
        label: "",
        data: jobCvMapping,
        backgroundColor: '#07598580'
    }

    const jobMappingPercentage = MapperPercentage(jobCvMapping);
    const updatedJobMappingPercentage = updatedCv ? MapperPercentage(updatedJobCvMapping) : 'N.A';

    const jobCvTrainingMapping = updatedCv ? trainingMapper(jobQualifications, updatedJobCvMapping, trainingRubricationData) : trainingMapper(jobQualifications, cvRubricationData, trainingRubricationData);
    const trainingMappingPercentage = trainingRubricationData.length > 0 ? MapperPercentage(jobCvTrainingMapping) : 'N.A';

    const headerStatsData = [jobMappingPercentage, updatedJobMappingPercentage, trainingMappingPercentage];
    // const headerStatsData = [jobMappingPercentage, updatedJobMappingPercentage, 'N.A'];

    const dispatch = useDispatch();
    const section = useSelector(state => state.section);
    return (
        <>
            {cvId && <div>
                <HeaderStats data={headerStatsData}/>
                <div className="container mx-auto flex flex-col gap-y-6 py-6">

                    <div className="flex flex-col gap-y-6">
                        <div className="flex gap-x-2">
                            <HorizontalBarChart cssClass={twMerge(cssClass, 'relative')}
                                                data={[cvQualificationsData, unCheckdCvQualificationsData]}
                                                subheader={t('CV_ANALYSIS')} header={cvQualificationsHeader}
                                                height={height} info="top_cv_qualifications_info"
                                                restrictionButtonUrl="/cv-health-restrictions" stacked={true}
                                                restrictionTitle={t("CV Health restrictions")}
                                                type={'cv'}
                            />
                            {jobRubricationData.length > 0 &&
                                <HorizontalBarChart cssClass={twMerge(cssClass, 'relative')}
                                                    data={[jobQualificationsData, unCheckdJobQualificationsData]}
                                                    subheader={t('JOB_ANALYSIS_Menu')} header={jobQualificationsHeader}
                                                    height={height} info="top_job_qualifications_info"
                                                    restrictionButtonUrl="/job-health-restrictions" stacked={true}
                                                    restrictionTitle={t("JOB Health restrictions")}
                                                    type={'job'}/>}
                        </div>
                        <ActualQualifications canUpdate={section === 0}/>


                        {jobRubricationData.length > 0 &&
                            <RadarChart height={200} header={t('cv_job_mapping')} data={[jobMappingData]}
                                        info="cv_job_mapping_info"
                                        cssClass="relative flex flex-col bg-gray-50 rounded-xl px-4 py-5 shadow-md"/>}
                        {jobRubricationData.length > 0 &&
                            <MapChart header={t('Jobs_loaction')} data={dataJobList} info="job_map_info"
                                      cssClass="relative flex flex-col bg-gray-50 rounded-xl px-4 py-5 shadow-md"
                                      shadow={false}/>}

                    </div>

                    <div className='flex justify-end'>
                        <button
                            className="ml-auto bg-green-600 border-green-600 border text-white relative items-center text-center font-semibold uppercase text-sm py-1 px-8 rounded-full shadow-sm hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-green-700"
                            onClick={() => dispatch(setSection(section + 1))}>{t('next')}
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 fill="currentColor"
                                 className="absolute right-1 top-1/2 h-5 -translate-y-1/2 transform"
                                 viewBox="0 0 16 16">
                                <path
                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>}

        </>
    )
}

export default MatchingJobsCV1Section