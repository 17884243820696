import React, {useEffect, useState} from 'react';
import axiosClient from "api/axiosClient";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import JobLoading from '../../Loadings/jobLoading';
import {setJobId, setJobTitle} from 'redux/reducer/searchReducer';
import {setTrainingId} from 'redux/reducer/searchReducer';
import {addTrainingList} from 'redux/reducer/trainingListReducer';
import {setTrainingRubricationData} from 'redux/reducer/searchReducer';
import {setShowLoader} from 'redux/reducer/searchReducer';
import {setJobRubricationData} from 'redux/reducer/searchReducer';
import missingItems from "utils/missingItems";
import {setCheckedQualifications} from 'redux/reducer/updatedCvsReducer';
import {checkJobOption} from 'redux/reducer/jobOptionsReducer';
import {clearCheckedJobOptions} from 'redux/reducer/jobOptionsReducer';
import {addJob} from 'redux/reducer/jobListReducer';
import {addJobOptions} from 'redux/reducer/jobOptionsReducer';
import Info from 'components/Modals/Info';
import CheckJob from "./CheckJob";
import Popup from "../../Modals/Popup";
import JobPopup from "../JobPopup";
import DropdownJobs from "../../Inputs/DropdownJobs";
import Loading from "../../Loadings/Loading";
import axiosClientNode from "../../../api/axiosClientNode";

const SelectJob = () => {
    const {t, i18n} = useTranslation('site');
    let lang = i18n.language;
    lang = i18n.language.substring(0, 2).toUpperCase();
    const dispatch = useDispatch();
    const isUpdatedCv = useSelector(state => state.isUpdatedCv);
    let {
        cvId,
        jobId,
        resultNumber,
        cvRubricationData
    } = useSelector(state => state.searchInput, shallowEqual);
    const [showJobLoader, setShowJobLoader] = React.useState(false);
    const updatedCvs = useSelector(state => state.updatedCvs, shallowEqual);
    const jobList = useSelector(state => state.jobList, shallowEqual);
    const jobOptions = useSelector(state => state.jobOptions, shallowEqual);
    const selectedJobs = (jobOptions && jobOptions.length > 0) ? jobOptions.filter(job => job.checked) : [];
    const jobs = jobList.find(j => j.cvId === cvId);
    const resultLimit = resultNumber > 0 ? resultNumber : undefined
    const plusTextCv = cvRubricationData.sort((a, b) => b.ranking_score - a.ranking_score).slice(0, resultLimit).filter((el) => el.checked).map(rubrication => rubrication.sub_category_name).join(', ');

    const selectJobHandler = (e) => {
        const selectedjobId = e.target.value;
        dispatch(setJobId(selectedjobId));
        dispatch(setJobTitle(e.target?.selectedOptions[0]?.text));
        getJobRubrication(selectedjobId);
        dispatch(setTrainingId(''));
        dispatch(addTrainingList([]));
        dispatch(setTrainingRubricationData([]));
    }
    const [selectedForDropdown, setSelectedForDropdown] = useState(selectedJobs[0]||null);
    const selectJobHandlerDropdown = (e) => {
        const selectedjobId = e.target_profile_id;
        setSelectedForDropdown(e);
        console.log(e)
        dispatch(setJobId(selectedjobId));
        dispatch(setJobTitle(e.text));
        getJobRubrication(selectedjobId);
        dispatch(setTrainingId(''));
        dispatch(addTrainingList([]));
        dispatch(setTrainingRubricationData([]));
    }
    useEffect(() => {
        if (
            (selectedForDropdown && !selectedJobs.some((job) => job.target_profile_id === selectedForDropdown.target_profile_id)) ||
            (selectedForDropdown === null && selectedJobs && selectedJobs.length > 0)
        ) {
            setSelectedForDropdown(selectedJobs[0]);
            selectJobHandlerDropdown(selectedJobs[0]);
        }
    }, [selectedJobs, selectedForDropdown]);

    const getJobRubrication = (jobId) => {
        if (jobId) {
            dispatch(setShowLoader(true));
            axiosClientNode.get(`/job/${jobId}`).then(res => {
                const { rubrication_items } = res.data;
                dispatch(setJobRubricationData(rubrication_items));
                dispatch(setShowLoader(false));
                axiosClientNode.get(`/job/getCheckedQualifications?cvId=${cvId}&jobId=${jobId}`).then(res=> {
                    console.log(res.data)
                    if(res.data.checkedQualifications) {
                        dispatch(
                            setCheckedQualifications(
                                cvId,
                                jobId,
                                res.data.checkedQualifications.checkedQualifications,
                                res.data.checkedQualifications.cvRubrications,
                                res.data.checkedQualifications.plusText
                            )
                        );
                    }
                })
            }).catch(err => dispatch(setShowLoader(false)))

            // axiosClient.get(`/Rubrication/GetJobRubricationById?jobId=${jobId}`).then(res => {
            //     if (res.status === 200) {
            //         const {rubrication_items} = res.data;
            //         dispatch(setJobRubricationData(rubrication_items));
            //         const updatedCv = updatedCvs.find(d => d.uniqueId === cvId + jobId);
            //         if (updatedCv?.uniqueId !== cvId + jobId) {
            //             const sortedJobRubrications = rubrication_items.sort((a, b) => b.ranking_score - a.ranking_score);
            //             const jobQualifications = resultNumber > 0 ? sortedJobRubrications.slice(0, 10) : sortedJobRubrications;
            //             const missingInCv = missingItems(jobQualifications, cvRubricationData);

            //             const missingInCvUpdated = missingInCv.filter(elm => elm.category_id !== 9000 && elm.category_id !== 9300);

            //             const plusText = missingInCvUpdated.reduce((prev, curr) => {
            //                 if (prev === '') {
            //                     return curr.sub_category_name;
            //                 }
            //                 return prev + ', ' + curr.sub_category_name;

            //             }, "");
            //             dispatch(setCheckedQualifications(cvId, jobId, [], cvRubricationData, plusText));
            //         }
            //     }
            //     dispatch(setShowLoader(false));
            // }).catch(err => dispatch(setShowLoader(false)));
        } else {
            dispatch(setJobRubricationData([]));
            dispatch(setShowLoader(false));
        }

    }

    const handleJobOption = (index, target_profile_id, title, checked) => {
        dispatch(checkJobOption(index, !checked));
        if (checked && jobId === target_profile_id && selectedJobs.length > 1) {
            const updatedSelectedJobList = selectedJobs.filter(j => j.target_profile_id !== target_profile_id);
            if (updatedSelectedJobList.length > 0) {
                const nextJob = updatedSelectedJobList[0];
                dispatch(setJobId(nextJob.target_profile_id));
                // Assuming you want to trigger a change event for an element with id 'job_list'
                const jobList = document.querySelector('#job_list');
                if (jobList) {
                    jobList.value = nextJob.target_profile_id;
                    jobList.dispatchEvent(new Event('change', {bubbles: true}));
                }
            }
        }
    }
    const [openPopup, setOpenPopup] = useState(false);

    const [showJobId,setShowJobId] = useState(null)
    const showDetails = (target_profile_id) => {
        setShowJobId(target_profile_id);
        setOpenPopup(true)
    }


    const removeSelectedJob = (id) => {
        // const updatedSelectedJobs = selectedJobs.filter(j => j.id !== id);
        const index = jobOptions.findIndex(j => j.target_profile_id === id);
        dispatch(checkJobOption(index, false));
        // setSelectedJobs(updatedSelectedJobs);

    }
    const clearJobOptions = () => {
        // setSelectedJobs([]);
        dispatch(clearCheckedJobOptions());
    }
    const findOptimizedJobs = () => {

        dispatch(setShowLoader(true));
        setShowJobLoader(true);

        const selected_job_ids = selectedJobs.map(j => j.target_profile_id);
        const body = {
            selected_job_ids,
            ref_profile_id: cvId,
            actuality: -60,
            affiliation_filter: "NotFiltered",
            employment_filter: "NotFiltered",
            languages: [
                "FR", "DE", "EN"
            ],
            language: lang,
            fallback_language: "DE"
        };
        axiosClient.post(`/Matching/GetOptimizedJobMatch`, body).then(res => {
            if (res.status === 200) {
                if (res.data && res.data.length) {
                    let jobs = {
                        cvId,
                        jobList: []
                    };
                    let firstJobId = '';
                    res.data.map((data, index) => {
                        const {title, company, target_profile_id, ranking_score, city, position} = data;
                        const job = {
                            title,
                            company,
                            target_profile_id,
                            ranking_score,
                            city,
                            position
                        }
                        jobs.jobList.push(job);
                        if (index === 0) {
                            firstJobId = target_profile_id;

                        }

                    })
                    dispatch(addJob(jobs));
                    dispatch(setJobId(firstJobId));
                    const select_job = document.querySelector('#job_list');
                    select_job.value = firstJobId;
                    select_job.dispatchEvent(new Event('change', {bubbles: true}));

                }
            } else {
                dispatch(setShowLoader(false));
            }
            setShowJobLoader(false);
        }).catch(err => {
            dispatch(setShowLoader(false));
        });
    }

    const handleSubmit = () => {
        if (!jobId) {
            if (jobs && jobs.jobList.length > 0) {
                dispatch(setJobId(jobs.jobList[0].target_profile_id))
                const select_job = document.querySelector('#job_list');
                select_job.value = jobs.jobList[0].target_profile_id;
                select_job.dispatchEvent(new Event('change', {bubbles: true}));

            } else {
                jobMatchingHandler(cvId);
            }
        }
    }

    const jobMatchingHandler = (cvId) => {
        const selectedJobs = jobList.find(item => item.cvId === cvId);

        if (!selectedJobs) {
            findJobs(cvId);
        }
    }

    const findJobs = (cvId) => {
        dispatch(setShowLoader(true));
        setShowJobLoader(true);
        const body = {
            ref_profile_id: cvId,
            actuality: -60,
            affiliation_filter: "NotFiltered",
            employment_filter: "NotFiltered",
            languages: [
                "EN", "DE", "FR"
            ],
            language: lang,
            fallback_language: "DE"
        };
        axiosClient.post(`/Matching/GetJobMatch`, body).then(res => {
            if (res.status === 200) {
                if (res.data && res.data.length) {
                    let jobs = {
                        cvId,
                        jobList: []
                    };
                    let firstJobId = '';
                    res.data.map((data, index) => {
                        const {title, company, target_profile_id, ranking_score, city, position} = data;
                        const job = {
                            title,
                            company,
                            target_profile_id,
                            ranking_score,
                            city,
                            position
                        }
                        jobs.jobList.push(job);
                        if (index === 0) {
                            firstJobId = target_profile_id;
                        }


                    })
                    dispatch(addJob(jobs));
                    dispatch(addJobOptions(jobs.jobList));
                    dispatch(setJobId(firstJobId));
                    dispatch(checkJobOption(0, true))
                    const select_job = document.querySelector('#job_list');
                    select_job.value = firstJobId;
                    select_job.dispatchEvent(new Event('change', {bubbles: true}));

                }
            } else {
                dispatch(setShowLoader(false));
            }
            setShowJobLoader(false);
        }).catch(err => {
            dispatch(setShowLoader(false));
            setShowJobLoader(false);


        });
    }

    useEffect(() => {
        if (!jobs) {
            findJobs(cvId);
        }
    }, [jobs]);


    return (
        <>
            {jobOptions.length === 0 && <Loading/>}
            <div className="px-3 w-full pt-5 flex flex-col gap-y-6">
                <div className="flex flex-wrap gap-4 max-h-500-px scrollable-div overflow-x-auto scrollbox py-1 justify-center">
                    {
                        jobOptions && jobOptions.length > 0 && jobOptions.map((job, index) => {
                            let checked = job.checked ?? false;
                            return (
                                <CheckJob key={index} job={job} checked={checked} action={handleJobOption} index={index}
                                          showDetails={showDetails}/>
                            )
                        })
                    }

                </div>
                {/*<input type="checkbox" checked={checked} className="ml-2" onChange={()=>handleJobOption(index,job.target_profile_id,job.title ,checked)} />*/}

                <Info cssClasses="absolute right-6 top-4" msg="select_job_info"/>


                <div className="relative flex flex-col gap-y-6">
                    {selectedJobs.length > 0 &&
                        <div className="flex gap-x-2">
                            <label htmlFor="selected_job_list " className="font-semibold flex">
                                {t('selected_jobs')}
                            </label>

                            <button
                                className="underline text-blue-500 border-none bg-transparent capitalize outline-none focus:outline-none ease-linear transition-all duration-150"
                                onClick={clearJobOptions}
                            >
                                {t('clear')}
                            </button>

                        </div>
                    }
                    <div className="flex gap-1 flex-wrap overflow-y-auto max-h-300-px scrollable-div scrollbox">
                        {selectedJobs.length > 0 && selectedJobs.map(job =>
                            <div key={job.target_profile_id}
                                 className=" bg-lightBlue-800 text-white py-1 pr-1 pl-8 rounded-full flex gap-x-2 items-center font-semibold uppercase text-sm">
                                {job.title}
                                <button className="focus:outline-none"
                                        onClick={() => removeSelectedJob(job.target_profile_id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                         className="w-5 hover:text-gray-200 delay-150 duration-300 ease-in-out"
                                         viewBox="0 0 16 16">
                                        <path
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                    </svg>
                                </button>
                            </div>)}
                    </div>
                </div>
            </div>
            {selectedJobs && selectedJobs.length > 0 &&
                <div className="flex align-center gap-5 mt-10">
                    <div className="w-full mx-auto">
                        <label htmlFor="job_list"
                               className="font-semibold flex gap-1">{t('Choose_a_job')}{showJobLoader &&
                            <JobLoading/>} </label>

                        <DropdownJobs list={selectedJobs} setSelected={selectJobHandlerDropdown}
                                      selected={selectedForDropdown}/>
                    </div>
                </div>}
            <Popup open={openPopup} closePopup={setOpenPopup}>
                <div>
                    <JobPopup id={showJobId} isPopup={true}></JobPopup>
                </div>
            </Popup>
        </>
    )
}
export default SelectJob;