import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const RestrictionDetailsModal = ({msg=""}) => {
    const { t } = useTranslation('site');
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <button

                type="button"
                onClick={() => setShowModal(true)}
                className="focus:outline-none"
            >
                <small className="ml-4 text-xs text-blue-600">{t('show_details')}</small>
            </button>
            {showModal && (

                <div style={{zIndex: 9999}}>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"


                    >
                        <div className="relative w-auto my-6 mx-auto w-1/3 top-24" >
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="  flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-2xl font-semibold">
                                        {t('Informations')}
                                    </h3>
                                    <button
                                        className=" ml-auto border-0  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                <span className="h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                                </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className=" py-2" style={{whiteSpace: "pre-line"}}>
                                        {msg}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </div>
            )}
        </>
    )
}

export default RestrictionDetailsModal;