import {twMerge} from "tailwind-merge";

const CheckJob = ({ job, action,index,showDetails }) => {
    const { checked, city, title,company } = job;
    const { url, name } = company;
    return <>
        <button onClick={() => action(index,job.target_profile_id, title, !!checked)}
            className={twMerge("focus:outline-none bg-lightBlue-800 text-white rounded-lg shadow-lg p-2 w-216-px min-h-36 cursor-pointer flex flex-col gap-4 justify-between hover:bg-lightBlue-900 delay-150 duration-300 ease-in-out text-left", !checked ? 'bg-gray-100 text-gray-800 hover:bg-gray-200' : '')}>
            <div className="flex justify-between gap-1 w-full">
                <div className="flex gap-1 flex-grow">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                         className="w-3 h-3 flex-shrink-0 mt-0.5" viewBox="0 0 16 16">
                        <path
                            d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z"/>
                        <path
                            d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z"/>
                    </svg>
                    <span className="font-semibold text-xs leading-3">{name}</span>
                </div>
                <div className={twMerge("extertnal-checkbox", checked ? 'checked' : '')}>
                    {checked && <span className="checked-checkbox"></span>}
                </div>
            </div>
            <div className="">{title}</div>
            <div className="flex items-center gap-1 w-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-3 h-3" viewBox="0 0 16 16">
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                </svg>
                <span className="flex-grow text-xs">{city}</span>
                <button className="underline text-xs focus:outline-none relative" onClick={(e)=> {
                    e.stopPropagation();
                    showDetails(job.target_profile_id);
                }}>Show details</button>
            </div>
        </button>

    </>
}

export default CheckJob;