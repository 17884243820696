import React, {useState} from "react";
import HeaderSection from "components/Sections/HeaderSection";
// import LogoDynaskills from "../../assets/img/logodynaskills.png";
import {faEye, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Step0 from "./steps/Step0";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/step5";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Button from "../../components/Buttons/Button";
import {twMerge} from "tailwind-merge";
import {setSection} from "../../redux/reducer/sectionReducer";

function Form() {
    const {t} = useTranslation('site');
    const initialFormData = {
        firstname: "",
        lastname: "",
        actualJob: "",
        postalCode: "",
        city: "",
        country: "",
        phone: "",
        email: "",
        socialLinks: [
            {
                id: 1,
                name: "",
                link: "",
                createdAt: Date.now(),
            }
        ],
        workHistories: [
            {
                id: 1,
                createdAt: Date.now(),
                JobTitle: "",
                employer: "",
                city: "",
                country: "",
                startDate: "",
                endDate: "",
                currentJob: false,
                jobDescription: ""
            }
        ],
        officialTitles: [
            {
                id: 1,
                createdAt: Date.now(),
                officialTitle: "",
                school: "",
                year: ""
            }
        ],
        furtherEducations: [
            {
                id: 1,
                createdAt: Date.now(),
                furtherEducation: "",
                school: "",
                year: ""
            }
        ],
        skills: [
            {
                id: 1,
                createdAt: Date.now(),
                skillName: '',
                rating: 0
            },
            {
                id: 2,
                createdAt: Date.now() + 2,
                skillName: '',
                rating: 0
            },
            {
                id: 3,
                createdAt: Date.now() + 4,
                skillName: '',
                rating: 0
            }
        ],
        languages: [
            {
                id: 1,
                createdAt: Date.now(),
                language: '',
                level: '',
                isNative: false,
            }
        ],
        professionalSummary: "",
        cvTitle: ""
    };
    const generatedCv = localStorage.getItem('generatedCv');
    let form = generatedCv ? JSON.parse(generatedCv) : initialFormData;
    const [page, setPage] = useState(0);
    const [formData, setFormData] = useState(form);
    const FormPages = [
        {
            title: t('step_1_title'),
            header: t('step_1_header'),
            subheader: t('step_1_subheader')
        },
        {
            title: t('step_2_title'),
            header: t('step_2_header'),
            subheader: t('step_2_subheader')
        },
        {
            title: t('step_3_title'),
            header: t('step_3_header'),
            subheader: t('step_3_subheader')
        },
        {
            title: t('step_4_title'),
            header: t('step_4_header'),
            subheader: t('step_4_subheader')
        },
        {
            title: t('step_5_title'),
            header: t('step_5_header'),
            subheader: t('step_5_subheader')
        },
        {
            title: t('preview'),
            header: "",
            subheader: ""
        }
    ];

    const saveCv = () => {

        localStorage.setItem('generatedCv', JSON.stringify(formData));


    }

    const PageDisplay = () => {
        if (page === 0) {
            return <Step0 formData={formData} setFormData={setFormData}/>;
        } else if (page === 1) {
            return <Step1 formData={formData} setFormData={setFormData}/>;
        } else if (page === 2) {
            return <Step2 formData={formData} setFormData={setFormData}/>;
        } else if (page === 3) {
            return <Step3 formData={formData} setFormData={setFormData}/>;
        } else if (page === 4) {
            return <Step4 formData={formData} setFormData={setFormData}/>;
        } else {
            return <Step5 formData={formData} setFormData={setFormData}/>;
        }
    };


    const NextButton = () => {
        if (page < 4) {
            return (<button
                className="block ml-auto bg-green-600 border-green-600 border text-white relative items-center text-center font-semibold uppercase text-sm py-1 px-8 rounded-full shadow-sm hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-green-700"
                onClick={() => setPage(page + 1)}>{t('next')}
                <svg xmlns="http://www.w3.org/2000/svg"
                     fill="currentColor"
                     className="absolute right-1 top-1/2 h-5 -translate-y-1/2 transform"
                     viewBox="0 0 16 16">
                    <path
                        d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                </svg>
            </button>)
        } else if (page === 4) {
            return (<button
                className="block ml-auto bg-green-600 border-green-600 border text-white relative items-center text-center font-semibold uppercase text-sm py-1 px-8 rounded-full shadow-sm hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-green-700"
                onClick={() => setPage(page + 1)}>{t('preview')}
                <FontAwesomeIcon icon={faEye} className="absolute right-1 top-1/2 h-5 -translate-y-1/2 transform"/>
            </button>)
        } else {
            return <Button className="block" onClick={() => saveCv()}>{t('save')}</Button>
        }
    }

    return (
        <>
            {/*<HeaderSection header={FormPages[page].header} subheader={FormPages[page].subheader} />*/}
            <div className="flex container w-full mx-auto justify-between py-10">
                <Link
                    className="text-gray-600 relative items-center text-center font-semibold uppercase text-sm py-1 px-8 rounded-full shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 border border-gray-600"
                    to="/matching-jobs"
                >
                    <FontAwesomeIcon icon={faArrowLeft}
                                     className="absolute left-2 top-1/2 h-5 -translate-y-1/2 transform"/>{t('back')}
                </Link>
                <Link to="/cover-letter"
                      className="cursor-pointer border text-white relative items-center text-center font-semibold uppercase text-sm py-1 px-8 rounded-full shadow-sm hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 bg-lightBlue-800 hover:bg-lightBlue-900">

                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-pencil-square absolute left-2 top-1/2 h-5 -translate-y-1/2 transform"
                         viewBox="0 0 16 16">
                        <path
                            d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                    </svg>
                    {t('create_cover_letter')}
                </Link>
            </div>

            <div className="container w-full mx-auto">
                <div
                    className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg bg-blue-50 border-0 rounded-xl">
                    <div className="rounded-t bg-white mb-0 px-6 py-6">

                        <div className="text-center flex flex-wrap justify-center gap-14">
                            {FormPages.map((step, index) => (
                                <button
                                    key={index}
                                    className={twMerge('focus:outline-none', index === page ? `text-blueGray-700  font-bold` : `text-blueGray-400  font-bold`)}
                                    onClick={() => setPage(index)}
                                >{step.title}</button>
                            ))}
                        </div>
                    </div>
                    {PageDisplay()}
                    <div className="rounded-b bg-white mb-0 px-6 py-6 flex justify-between">

                        {page > 0 && (<Button onClick={() => setPage(page - 1)}
                                              className="ml-0 bg-white text-gray-600 relative items-center text-center font-semibold uppercase text-sm py-1 px-8 rounded-full shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 border border-gray-600">
                            <FontAwesomeIcon icon={faArrowLeft}
                                             className="absolute left-2 top-1/2 h-5 -translate-y-1/2 transform"/>{t('back')}
                        </Button>)}
                        <NextButton/>
                    </div>
                </div>

            </div>

        </>
    );
}

export default Form;
