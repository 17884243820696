import Info from "../../Modals/Info";
import React from "react";
import {useTranslation} from "react-i18next";
import SpinLoading from "../../Loadings/SpinLoading";
import Button from "../../Buttons/Button";


interface EditCvNamePros {
    setCvTitle: (value: (((prevState: string) => string) | string)) => void,
    uploadCvHandler: ()=> {},
    showLoader: boolean,
    cvTitle: string
}

const EditCvName = ({setCvTitle, uploadCvHandler, showLoader, cvTitle}: EditCvNamePros) => {
    const {t} = useTranslation('site');

    return <div className="flex gap-4 items-center">
        <div className="flex justify-center relative  ">
            <input type="text"
                   className="w-full rounded-full shadow-sm hover:shadow-lg outline-none focus:outline-none text-sm  py-1 px-3"
                   placeholder={t('title_of_cv')} value={cvTitle}
                   onChange={(e) => setCvTitle(e.target.value)}/>

        </div>
        <Button type="button" className="flex gap-x-2" onClick={uploadCvHandler}
                right>{t('start_now')}{showLoader &&
            <SpinLoading color="white" insideBtn/>}
        </Button>
        <Info cssClasses="" msg="cv_title_info"/>
    </div>
}

export default EditCvName;