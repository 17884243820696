import { useState, useRef } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";

function Language({ id, language = "", level = "", isNative = false, createdAt, formData, setFormData }) {
    const { t } = useTranslation('site');
    
    const [languageState, setLAnguageState] = useState(language);
    const [levelState, setRatingState] = useState(level);
    const [isNativeState, setIsNativeState] = useState(isNative);

    const languageObjectState = {
        id,
        createdAt,
        language: languageState,
        level: levelState,
        isNative: isNativeState,
    };
    const levels = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
    
    const updateLanguage = (e) => {
        setLAnguageState(e.target.value);
        const oldLanguages = formData.languages.filter(el => el.id !== id);
        const newLanguage = { ...languageObjectState, language: e.target.value };
        const updatedLanguages = [...oldLanguages, newLanguage].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, languages: updatedLanguages });

    }

    const updateLevel = (e) => {
        
        setRatingState(e.target.value);
        const oldLanguages = formData.languages.filter(el => el.id !== id);
        const newLanguage = { ...languageObjectState, level: e.target.value };
        const updatedLanguages = [...oldLanguages, newLanguage].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, languages: updatedLanguages });

    }

    const updateIsNative = (e) => {
        
        setIsNativeState(!isNativeState);
        const oldLanguages = formData.languages.filter(el => el.id !== id);
        const newLanguage = { ...languageObjectState, isNative: !isNativeState};
        const updatedLanguages = [...oldLanguages, newLanguage].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, languages: updatedLanguages });

    }


    const removeLanguage = () => {
        const oldLanguages = formData.languages.filter(el => el.id !== id);
        const updatedLanguages = [...oldLanguages].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, languages: updatedLanguages });
    }
    
    return (
        <>
            <div className="w-full lg:w-6/12 px-4 ">
                <div className="relative w-full mb-10">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                        {t('languages')}
                    </label>
                    <input
                        type="email"
                        className="text-left focus:outline-none block w-full relative py-2 px-4 cursor-pointer text-gray-500 bg-white rounded-md border border-blueGray-200"
                        value={languageState}
                        onChange={(e) =>
                            updateLanguage(e)
                        }
                        
                    />

                </div>
            </div>
            <div className="w-full lg:w-4/12 px-4 ">
                <div className="relative w-full mb-10">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                        {t('level')}
                    </label>
                    <div className="flex gap-10 py-2">
                        {levels.map((l,index) => (
                            <label className="flex flex-col font-bold" key={index}>
                                <input
                                    type="checkbox"
                                    value={l}
                                    checked={levelState===l}
                                    onChange={(e) => updateLevel(e)}
                                />
                                <span>{l}</span>
                            </label>
                        ))}
                        
                    </div>
                </div>
            </div>
            <div className="w-full lg:w-2/12 px-4 ">
                <div className="relative w-full mb-10 flex flex-between">
                    <div>
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                            {t('native_language')}
                        </label>
                        <label><input type="checkbox" 
                            onChange={(e) => updateIsNative(e)}
                        /> </label>
                    </div>
                    <div className="mt-6">
                        {formData.languages.length === 1 ? (
                            <button onClick={() => removeLanguage()} disabled="disabled" style={{ cursor: 'not-allowed' }} >
                                <FontAwesomeIcon icon={faTrash} style={{ color: "gray" }} />
                            </button>
                        ): (
                            <button onClick={() => removeLanguage()} >
                                <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
                            </button>
                            )}
                    </div>
                </div>
            </div>

            {/* <div className="w-full lg:w-1/12 px-4 ">
                <div className="relative w-full mb-10 mt-10">
                    
                    {formData.languages.length === 1 ? (
                        <button onClick={() => removeLanguage()} disabled="disabled" style={{ cursor: 'not-allowed' }} >
                            <FontAwesomeIcon icon={faTrash} style={{ color: "gray" }} />
                        </button>
                    ): (
                        <button onClick={() => removeLanguage()} >
                            <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
                        </button>
                    )}
                </div>
            </div> */}
                
            
                
            
        </>
    )
} 
export default Language;