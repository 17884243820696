import React from "react";
import { twMerge } from "tailwind-merge";


interface CardStatsProps {
    statSubtitle?: string;
    statTitle?: string;
    statPercentColor?: string;
    statDescripiron?: string;
    statIconName?: string;
    statIconColor?: string;
    containerStyle?: string;
}
const CardStats= ({
                      statSubtitle = "Traffic",
                      statTitle = "350,897",
                      statPercentColor = "text-emerald-500",
                      statDescripiron = "Since last month",
                      statIconName,
                      statIconColor = "bg-red-500",
                      containerStyle,
                  }:CardStatsProps) => {
    return (
        <div className={twMerge('relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-md', containerStyle)}>
            <div className="flex-auto p-4">
                <div className="flex flex-wrap">
                    <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                            {statSubtitle}
                        </h5>
                        <span className="font-semibold text-xl text-blueGray-700">
                          {statTitle}
                        </span>
                    </div>
                    {/*{statIconName.length >0 && <div className="relative w-auto pl-4 flex-initial">*/}
                    {/*    <div*/}
                    {/*        className={*/}
                    {/*            "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full " +*/}
                    {/*            statIconColor*/}
                    {/*        }*/}
                    {/*    >*/}
                    {/*        <i className={statIconName}></i>*/}
                    {/*    </div>*/}
                    {/*</div>}*/}
                </div>
                <p className="text-sm text-blueGray-400 mt-4">
                  <span className="whitespace-nowrap font-semibold text-sm text-lightBlue-800">
                    {statDescripiron}
                  </span>
                </p>
            </div>
        </div>
    );
}
export default CardStats;
