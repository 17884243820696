import {twMerge} from "tailwind-merge";
import React, {useState} from "react";

const Toggle = ({checked, id, onChange, className}) => {
    const [checkedToggle, setCheckedToggle] = useState(checked);

    return (
        <label
            htmlFor={`toggle${id}`}
            className={twMerge("flex items-center cursor-pointer select-none gap-2", className)}
        >
            <div className="relative">
                <input
                    type="checkbox"
                    id={`toggle${id}`}
                    className="peer sr-only"
                    checked={checkedToggle}
                    onChange={(e) => {
                        setCheckedToggle(!checkedToggle);
                        onChange(e)
                    }}
                />

                <div
                    className={twMerge("block h-6 rounded-full bg-lightBlue-200 w-10 shadow", checkedToggle ? 'bg-lightBlue-800' : '')}
                ></div>
                <div
                    className={twMerge("absolute w-4 h-4 transition bg-white rounded-full dot left-1 top-1 delay-150 duration-300 ease-in-out shadow", checkedToggle ? 'right-1 left-auto' : '')}
                ></div>
            </div>
        </label>
    );
};

export default Toggle;
