import React, {useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {

    setResultNumber,
    setShowLoader,
} from '../../redux/reducer/searchReducer';
import { useTranslation } from "react-i18next";
import SelectCV from '../../components/Sections/SearchSections/SelectCV'
import SelectJob from '../../components/Sections/SearchSections/SelectJob'
import OptimizeCv from '../../components/Sections/SearchSections/OptimizeCv'
import SelectTraining from '../../components/Sections/SearchSections/SelectTraining';
import AddCv from '../../components/Sections/SearchSections/AddCv';
import MatchingJobsCVSection from '../../components/Sections/MatchingJobsCVSection';
import BoostChancesSection from "./BoostChancesSection";
import BoostByTrainingSection from "./BoostByTrainingSection";
import Loading from 'components/Loadings/Loading'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import {setSection} from "redux/reducer/sectionReducer";
import Info from "components/Modals/Info";
import Stepper from "./Stepper";
import {twMerge} from "tailwind-merge";
import Popup from "../Modals/Popup";
import JobPopup from "./JobPopup";
import Button from "../Buttons/Button";


const Search = ({headers = [], showTraining = false, showJobApply, setShowJobApply}) => {
    const {t} = useTranslation('site');
    //const [section, setSection] = React.useState(0);
    const dispatch = useDispatch();
    const section = useSelector(state => state.section);
    let {
        cvId, jobId, jobTitle, resultNumber, showLoader
    } = useSelector(state => state.searchInput, shallowEqual);
    const addCv = useSelector(state => state.addCv)
    const formSections = [{
        'title': addCv ? t('add_cv') : t('set_cv'),
        'icon': addCv ? <i className="fa fa-file-upload "></i> : <i className="fa fa-file-alt"></i>,
        'disabled': false
    }, {
        'title': t('select_job'), 'icon': <i className="fa fa-briefcase"></i>, 'disabled': !cvId
    }, {
        'title': t('optimise_cv'), 'icon': <i className="fas fa-rocket"></i>, 'disabled': !jobId
    }, {
        'title': t('select_training'), 'icon': <i className="fas fa-graduation-cap"></i>, 'disabled': !jobId
    },];

    const SectionDisplay = () => {
        let displayedSection;
        switch (section) {
            case 0 :
                displayedSection = addCv ? <AddCv/> : <SelectCV/>;
                break;
            case 1 :
                displayedSection = <SelectJob/>;
                break;
            case 2 :
                displayedSection = <OptimizeCv/>;
                break;
            default:
                displayedSection = <SelectTraining/>;

        }
        return displayedSection;
    };

    const [results, setResults] = useState(false);
    const nextBtnDisabled = () => {
        return !((section === 0 && cvId) || (section > 0 && jobId));

    }
    const diplayPage = () => {
        if (section === 0 || section === 1) {
            return <MatchingJobsCVSection/>
        } else if (section === 2) {
            return <BoostChancesSection/>;
        } else {
            return <BoostByTrainingSection/>;
        }

    }
    const selectResultNumberHandler = (e) => {
        dispatch(setResultNumber(results ? '10' : ''));
        dispatch(setShowLoader(true));
        setTimeout(() => {
            dispatch(setShowLoader(false));
        }, 500);
    }
    const [openPopup, setOpenPopup] = useState(false);
    return (

        <div className="flex flex-col gap-10 mt-20">
            <Stepper formSections={formSections} section={section} current={section + 1}
                     dispatch={dispatch}></Stepper>
            <div className="container mx-auto shadow-md bg-gray-50 rounded-xl p-12 gap-4 flex flex-col relative">
                <div>
                    {SectionDisplay()}
                </div>

                <div className="flex">
                    {section > 0 && <Button back withIcon onClick={() => dispatch(setSection(section - 1))}>{t('back')}</Button>}
                    {(section < 3 && !addCv) &&
                        (<Button next withIcon right disabled={nextBtnDisabled()}
                                    onClick={() => dispatch(setSection(section + 1))}>{t('next')}</Button>)}
                </div>

            </div>

            <div className="container mx-auto flex justify-between">
                <div className="text-center">

                    {jobTitle && <h1 className="text-bold text-2xl flex gap-x-2 items-center">
                        {jobTitle}
                        <Button onClick={() => setOpenPopup(true)}>{t('show_details')}</Button>
                    </h1>}
                </div>

                {cvId && <div className="relative flex flex-col gap-2 mr-6">
                    <div
                        className="font-semibold flex gap-2">{t('Number_of_results')} <Info
                        msg="number_of_results_info"/>
                    </div>
                    <label
                        htmlFor="toggleTwo"
                        className="flex items-center cursor-pointer select-none gap-2"
                    ><span
                        className={twMerge('w-6', !results ? 'font-semibold text-gray-800' : 'font-gray-400')}>10</span>
                        <div className="relative">
                            <input
                                type="checkbox"
                                id="toggleTwo"
                                className="peer sr-only"
                                checked
                                onChange={() => {
                                    setResults(!results);
                                    selectResultNumberHandler()
                                }}
                            />

                            <div
                                className={twMerge("block h-8 rounded-full bg-lightBlue-800 w-14", results ? 'bg-green-600' : '')}
                            ></div>
                            <div
                                className={twMerge("absolute w-6 h-6 transition bg-white rounded-full dot left-1 top-1 delay-150 duration-300 ease-in-out", results ? 'right-1 left-auto' : '')}
                            ></div>

                        </div>
                        <span
                            className={twMerge('w-6', results ? 'font-semibold text-gray-800' : 'font-gray-400')}>{t('all')}</span>
                    </label>
                </div>}
            </div>
            {showLoader ? <Loading/> : diplayPage()}
            <Popup open={openPopup} closePopup={setOpenPopup}>
                <div>
                    <JobPopup id={jobId} isPopup={true}></JobPopup>
                </div>
            </Popup>
        </div>)
}

export default Search;