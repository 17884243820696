import { TailSpin } from 'react-loader-spinner';
import {twMerge} from "tailwind-merge";

interface SpinLoadingProps {
    color?: string;
    className?: string;
    insideBtn?: boolean;
}

const SpinLoading = ({ color, className, insideBtn }: SpinLoadingProps) => {
    const classesAbsolute = 'absolute right-1 top-1/2 h-5 -translate-y-1/2 transform';
    return (
        <TailSpin
            ariaLabel="loading-indicator"
            wrapperClass={twMerge(insideBtn && classesAbsolute,className)}
            height="20"
            width="20"
            color={color || '#0284c7'}
        />
    );
};

export default SpinLoading;
