import {useTranslation} from "react-i18next";
import Button from "../Buttons/Button";


const UpdateProfileModal = ({text, showModal, setShowModal, setAction, goToButton}) => {
    const { t, i18n } = useTranslation('restrictions');

    return (
        <>

            {showModal && (

                <div style={{zIndex: 9999}}>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"


                    >
                        <div className="relative w-auto my-6 mx-auto w-1/3 top-24" >
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="  flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <i className="fas fa-exclamation-triangle"></i>
                                    <button
                                        className=" ml-auto border-0  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                <span className="h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                                </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className=" py-2" style={{whiteSpace: "pre-line"}}>
                                        {t(text)}
                                    </div>
                                    <div className="flex gap-4 justify-between">
                                        <div className={"flex justify-center"}>
                                            {goToButton}
                                        </div>
                                        <Button className="bg-green-600 border-green-600 border text-white relative items-center text-center font-semibold uppercase text-sm py-1 px-8 rounded-full shadow-sm hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-green-700" onClick={()=>{
                                            setAction('update');
                                            setShowModal(false);
                                        }}>{t('Update Profile')}</Button>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </div>
            )}
        </>
    )

}

export default UpdateProfileModal;