import React from 'react'
import HeaderStats from "components/Headers/HeaderStats.js";
import HorizontalBarChart from "components/Charts/HorizontalBarChart.js";
import RadarChart from "components/Charts/RadarChart.js";
import {useSelector} from "react-redux";
import Mapper from '../../utils/mapper';
import MapperPercentage from '../../utils/mapperPercentage';
import cvMapper from 'utils/cvMapper';
import missingItems from 'utils/missingItems';
import Table from "components/Cards/Table.js"
import trainingMapper from 'utils/trainingMapper';
import {useTranslation} from 'react-i18next';
import translateRubrications from '../../utils/translateRubrications';
import ActualQualifications from './ActualQualifications';
import {maxHeight} from "tailwindcss/lib/plugins";


function BoostChancesSection() {
    const {t, i18n} = useTranslation('site');
    const lang = i18n.language.substring(0, 2);

    let {
        cvId,
        jobId,
        resultNumber,
        cvRubricationData,
        jobRubricationData,
        trainingRubricationData
    } = useSelector(state => state.searchInput);
    // cvRubricationData = translateRubrications(cvRubricationData, lang);
    // jobRubricationData = translateRubrications(jobRubricationData, lang);
    trainingRubricationData = translateRubrications(trainingRubricationData, lang);
    const updatedCvs = useSelector(state => state.updatedCvs);
    const updatedCv = updatedCvs.find(d => d.cvId === cvId && d.jobId === jobId);

    const updatedCvRubrications = updatedCv ? updatedCv.cvRubrications : [];

    const cvJobMappingHeader = resultNumber > 0 ? t('TOP_10_CV_/_Job_mapping') : t('all_CV_/_Job_mapping');


    const sortedJobRubrications = jobRubricationData.sort((a, b) => b.ranking_score - a.ranking_score);
    const jobQualifications = resultNumber > 0 ? sortedJobRubrications.slice(0, 10) : sortedJobRubrications;
    const jobQualificationsData = {
        label: t('Scores_job'),
        data: jobQualifications,
        backgroundColor: '#16a34a'
    }


    const height = resultNumber === '' ? 250 : 100;
    const cvSocres = cvMapper(jobQualifications, cvRubricationData);
    const cvSocresData = {
        label: t('Scores_cv'),
        data: cvSocres,
        backgroundColor: '#075985'
    }

    const missingInCv = missingItems(jobQualifications, cvRubricationData);
    const missingInCvData = {
        label: "",
        data: missingInCv,
        backgroundColor: '#16a34a'
    }
    const jobCvMapping = Mapper(jobQualifications, cvRubricationData);
    const updatedJobCvMapping = Mapper(jobQualifications, updatedCvRubrications);


    const jobMappingPercentage = MapperPercentage(jobCvMapping);
    const updatedJobMappingPercentage = updatedCv ? MapperPercentage(updatedJobCvMapping) : 'N.A';

    const jobCvTrainingMapping = updatedCv ? trainingMapper(jobQualifications, updatedJobCvMapping, trainingRubricationData) : trainingMapper(jobQualifications, cvRubricationData, trainingRubricationData);
    const trainingMappingPercentage = trainingRubricationData.length > 0 ? MapperPercentage(jobCvTrainingMapping) : 'N.A';

    const headerStatsData = [jobMappingPercentage, updatedJobMappingPercentage, trainingMappingPercentage];


    const jobMappingData = {
        label: "",
        data: updatedCv ? updatedJobCvMapping : jobCvMapping,
        backgroundColor: '#075985'
    }
    const jobCvMappingHeader = updatedCv ? t('Completed_CV_/_Job mapping') : t('cv_job_mapping');

    return (
        <>
            <HeaderStats data={headerStatsData}/>
            <div className="flex flex-wrap ">
                <div className="container w-full mb-12 xl:mb-0 mx-auto">
                    <div className="flex flex-col gap-y-6">
                        <HorizontalBarChart cssClass="w-full" data={[cvSocresData, jobQualificationsData]}
                                            header={cvJobMappingHeader} height={height} legend={true} stacked={true}
                                            showButtons={false} info="top_cv_job_qualifications_info"/>

                        {/* <HorizontalBarChart cssClass="w-full" data={[missingInCvData]} header={t('Missing_in_CV_Menu')} height={100} info="missing_in_cv_info" /> */}
                        <ActualQualifications/>
                        <div className=" gap-x-6 flex-col lg:flex lg:flex-row">
                            <Table
                                cssClass="relative bg-gray-50 rounded-xl px-4 py-5 shadow-md flex flex-col gap-y-6 flex-grow mb-6 lg:mb-0 lg:w-1/2 w-full"
                                data={missingInCv} info="add_to_cv_info"/>
                            <RadarChart cssClass="mx-auto flex-grow lg:w-1/2 w-full" height={200} header={jobCvMappingHeader}
                                        data={[jobMappingData]} info="complete_cv_job_mapping_info"/>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BoostChancesSection