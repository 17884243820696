import { useEffect, useState } from "react";
import { PDFDownloadLink } from '@react-pdf/renderer';

import { faPlusCircle, faWrench, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QualificationsImg from "components/Inputs/jobApply/QualificationsImg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 as uuid } from 'uuid';
import Language from "components/Inputs/jobApply/Language";
import Diploma from "components/Inputs/jobApply/Diploma";
import JobFolderPdf from "components/Pdf/JobFolderPdf";
import React from "react";
import CvImg from "components/Inputs/jobApply/CvImg";
import DiplomaImg from "components/Inputs/jobApply/DiplomaImg";
import translateRubrications from "utils/translateRubrications";
import Mapper from "utils/mapper";
import MapperPercentage from "utils/mapperPercentage";
import axiosClient from "api/axiosClient";


import Loading from 'components/Loadings/Loading'



const JobDetailApply = ({ JobData, jobId }) => {
    
    const { t,i18n } = useTranslation('site');
    const lang = i18n.language.substring(0, 2);

    let { cvRubricationData } = useSelector(state => state.searchInput);
    
    
    const [jobRubricationData, setJobRubricationData] = useState([]);
    const [showLoader, setShowLoader] = useState(true);

    cvRubricationData = translateRubrications(cvRubricationData,lang);
    const jobRubricationDataTranslated = translateRubrications(jobRubricationData, lang);
    const sortedCvRubrications = cvRubricationData.sort((a, b) => b.ranking_score - a.ranking_score);
    const cvQualifications = sortedCvRubrications.slice(0, 10) ;
    const cvQualificationsTxt = cvQualifications.reduce((previousValue, currentValue) => {
        return `${previousValue} ${currentValue.sub_category_name}`;
    }, "");

    const sortedJobRubrications = jobRubricationDataTranslated.sort((a, b) => b.ranking_score - a.ranking_score);
    const jobQualifications = sortedJobRubrications.slice(0, 10);
    const jobQualificationsTxt = jobQualifications.reduce((previousValue, currentValue) => {
        return `${previousValue} ${currentValue.sub_category_name}`;
    }, "");

    const jobCvMapping = Mapper(jobQualifications, cvRubricationData);
    const matchingDegree = MapperPercentage(jobCvMapping);

    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    const getJobRubrication = () => {
            axiosClient.get(`/Rubrication/GetJobRubricationById?jobId=${jobId}`).then(res => {
                if (res.status === 200) {
                    const { rubrication_items } = res.data;
                    setJobRubricationData(rubrication_items);
                    setShowLoader(false);
                    
                }
                setShowLoader(false);
            }).catch(err => {
                console.log(err)
                setShowLoader(false);
            }); 
        
    }
    useEffect(() => {
        getJobRubrication();
    }, []);
    
    const initialFormData = {
        firstname: "",
        lastname: "",
        sexe: "",
        experience: "",
        diplomas: [
            {
                id: 1,
                createdAt: Date.now(),
                title: "",
                university: "",
                startDate: "",
                endDate: "",
                isChecked: false
            }
        ],
        languages: [
            {
                id: 1,
                createdAt: Date.now(),
                language: '',
                level: ''
            }
        ],
        qualificationsImg: '',
        cvImgs: [
            {
                id: 1,
                createdAt: Date.now(),
                src:""
            }
        ],
        diplomaImgs: [
            {
                id: 1,
                createdAt: Date.now(),
                src:""
            }
        ],
        jobQualifications: jobQualificationsTxt,
        cvQualifications: cvQualificationsTxt,
        otherPositions:''
    };

    let form = initialFormData;
    const [formData, setFormData] = useState(form);
    
    

    const addNewLanguage = () => {
        const oldLanguages = formData.languages;
        const newLanguage = {
            id: uuid(),
            createdAt: Date.now(),
            language: "",
            level: ""
        };

        const updatedLanguages = [...oldLanguages, newLanguage];
        setFormData({...formData, languages: updatedLanguages});
    }

    const addNewDiploma = () => {
        const oldDiplomas = formData.diplomas;
        const newDiploma = {
            id: uuid(),
            createdAt: Date.now(),
            title: "",
            university: "",
            startDate: "",
            endDate: "",
            isChecked: false

        };

        const updatedDiplomas = [...oldDiplomas, newDiploma];
        setFormData({...formData, diplomas: updatedDiplomas});
    }

    const addNewCvImg = () => {
        const oldCvImgs = formData.cvImgs;
        const newCvImg = {
            id: uuid(),
            createdAt: Date.now(),
            src: ""
        };

        const updatedCvImgs = [...oldCvImgs, newCvImg];
        setFormData({...formData, cvImgs: updatedCvImgs});
    }

    const addNewDiplomaImg = () => {
        const oldDiplomaImgs = formData.diplomaImgs;
        const newDiplomaImg = {
            id: uuid(),
            createdAt: Date.now(),
            src: ""
        };

        const updatedDiplomaImgs = [...oldDiplomaImgs, newDiplomaImg];
        setFormData({...formData, diplomaImgs: updatedDiplomaImgs});
    }

    if (showLoader) {
        
        return (
            <div id="job-apply">
                <Loading />
            </div>
        );
    }
    return (
        <div id="job-apply" className="container mx-auto bg-white relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded mb-12 xl:mb-0  px-4 mt-10">
            
            <div className="w-9/12 mx-auto" >
                <div className="flex justify-end mb-5">
                    <a
                    className=" text-white text-lg md:text-sm  items-center font-semibold bg-lightBlue-500 px-5 py-3  flex gap-2 "
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    >
                        <FontAwesomeIcon icon={faWrench} /> <span>{t('support')}</span>
                    </a>
                </div>
                <div className="flex mb-5">
                    <button
                        className=" text-white text-lg md:text-sm  items-center font-semibold bg-orange-500 px-10 py-3  flex gap-2 "
                        onClick={topFunction}
                    >
                        {t('return')}
                    </button>
                </div>

                <div className="py-10">
                    <h3 className="font-semibold text-lg mb-2"> {t('job_posting_title')} </h3>
                    <h1 className="font-bold text-xl mb-2">{JobData?.title}</h1>
                    <h1 className="font-bold text-xl mb-5">{JobData?.company?.name}</h1>
                    <div className="py-10 flex flex-col gap-8">
                        <div className="flex items-end">
                            <div className="w-3/12">
                                {t('sexe')}*
                            </div>
                            <div className="w-9/12">
                                <input
                                    type="text"
                                    className="w-full rounded h-10 bg-blueGray-200 border-0"
                                    value={formData.sexe}
                                    onChange={(e) =>
                                        setFormData({ ...formData, sexe: e.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex items-end">
                            <div className="w-3/12">
                                {t('firstname')}*
                            </div>
                            <div className="w-9/12">
                                <input
                                    type="text"
                                    className="w-full rounded h-10 bg-blueGray-200 border-0" 
                                    value={formData.firstname}
                                    onChange={(e) =>
                                        setFormData({ ...formData, firstname: e.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex items-end">
                            <div className="w-3/12">
                                {t('lastname')}*
                            </div>
                            <div className="w-9/12">
                                <input
                                    type="text"
                                    className="w-full rounded h-10 bg-blueGray-200 border-0" 
                                    value={formData.lastname}
                                    onChange={(e) =>
                                        setFormData({ ...formData, lastname: e.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="flex items-end">
                            <div className="w-3/12">
                                Cv qualifications*
                            </div>
                            <div className="w-9/12">
                                <textarea
                                    className="w-full rounded bg-blueGray-200 border-0 h-40" 
                                    value={formData.cvQualifications}
                                    onChange={(e) =>
                                        setFormData({ ...formData, cvQualifications: e.target.value })
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="flex items-end">
                            <div className="w-3/12">
                                Job qualifications*
                            </div>
                            <div className="w-9/12">
                                <textarea
                                    className="w-full rounded bg-blueGray-200 border-0 h-40" 
                                    value={jobQualificationsTxt}
                                    onChange={(e) =>
                                        setFormData({ ...formData, jobQualifications: e.target.value })
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="flex items-end">
                            <div className="w-3/12">
                            Other positions in the company*
                            </div>
                            <div className="w-9/12">
                                <textarea
                                    className="w-full rounded bg-blueGray-200 border-0 h-40" 
                                    value={formData.otherPositions}
                                    onChange={(e) =>
                                        setFormData({ ...formData, otherPositions: e.target.value })
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="flex flex-wrap relative  pb-8 mb-10">
                            <div className="w-3/12 pt-4">
                                {t('languages')}*
                            </div>
                            <div className="w-9/12 relative flex flex-col gap-8 ">
                                
                                {formData.languages.map(item=><Language key={item.id} formData={formData} setFormData={setFormData} {...item} />)}
                                
                            </div>
                            
                            <div className="absolute right-0 bottom-0">
                                <button onClick={()=>addNewLanguage()} >
                                    <FontAwesomeIcon icon={faPlusCircle} /> 
                                </button>
                            </div>
                        </div>

                        


                        <div className="flex flex-col gap-8 pb-8 relative mb-10">
                            
                            {formData.diplomas.map(item=><Diploma key={item.id} formData={formData} setFormData={setFormData} {...item} />)}
                            
                            <div className="absolute right-0 bottom-0">
                                <button onClick={()=>addNewDiploma()}>
                                    <FontAwesomeIcon icon={faPlusCircle} /> 
                                </button>
                            </div>
                            
                        </div>
                        

                        <div className="flex items-end mb-10">
                            <div className="w-3/12">
                                {t('experience')}*
                            </div>
                            <div className="w-3/12">
                                <input
                                    type="number"
                                    className="w-full rounded h-10 bg-blueGray-200 border-0" 
                                    value={formData.experience}
                                    onChange={(e) =>
                                        setFormData({ ...formData, experience: e.target.value })
                                    }
                                    />
                            </div>
                        </div>

                        <div>
                            <h6 className="text-lg">{t('upload_docs')}</h6>
                            <div className="w-9/12 float-right p-10 bg-blueGray-100 mt-10">
                                <div className="relative  pb-8 flex justify-between  mb-5 w-8/12 mx-auto">
                                    <span className="text-base">Mapping graphic​</span>
                                    <QualificationsImg formData={formData} setFormData={setFormData} />
                                    
                                </div>
                                <div className="relative  pb-8 flex justify-between  mb-5 w-8/12 mx-auto">
                                    <span className="text-base">{t('cv')}</span>
                                    <div className="flex flex-col gap-5">
                                        {formData.cvImgs.map(item=><CvImg key={item.id} formData={formData} setFormData={setFormData} {...item} />)}
                                    </div>
                                    <div className="absolute right-0 bottom-0">
                                        <button onClick={addNewCvImg}>
                                            <FontAwesomeIcon icon={faPlusCircle} /> 
                                        </button>
                                    </div>
                                </div>
                                <div className=" relative pb-8 flex justify-between  mb-5 w-8/12 mx-auto">
                                    <span className="text-base">{t('diplome_for_this_job')}</span>
                                    <div className="flex flex-col gap-5">
                                        {formData.diplomaImgs.map(item=><DiplomaImg key={item.id} formData={formData} setFormData={setFormData} {...item} />)}
                                    </div>
                                    
                                    <div className="absolute right-0 bottom-0">
                                        <button onClick={addNewDiplomaImg}>
                                            <FontAwesomeIcon icon={faPlusCircle} /> 
                                        </button>
                                    </div>
                                </div>
                            <small className="float-right"> {t('upload_notice')} </small>

                            </div>
                        </div>

                        <div className="w-96 mx-auto mt-10">
                            {/* <button className="bg-green-400 rounded text-xl text-white w-full py-3 uppercase font-bold">{t('apply')}</button> */}
                            <PDFDownloadLink className="bg-green-400 rounded text-xl text-white w-full py-3 px-10 uppercase font-bold" document={<JobFolderPdf formData={formData} companyName={JobData?.company?.name} matchingDegree={ matchingDegree } jobTitle={JobData?.title}  />} fileName={`job-folder.pdf`}>
                                {
                                    ({ blob, url, loading, error }) => {
                                        return loading ? t('loading_document') : t('apply');
                                    }
                                }
                            </PDFDownloadLink>
                            
                        </div>
                        

                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default JobDetailApply;