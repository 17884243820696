import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import SocialLink from "./SocialLink";
import { useTranslation } from "react-i18next";

function Step0({ formData, setFormData }) {
    const { t } = useTranslation('site');

return (

    <div className="flex-auto px-4 lg:px-10 py-10 ">
        <form>
            <div className="flex flex-wrap pt-5">
            <div className="w-full lg:w-12/12 px-4 ">
                    <div className="relative w-full mb-10">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                            {t('professional_summary')}
                        </label>
                        <textarea
                            type="text"
                            className="text-left focus:outline-none block w-full relative py-2 px-4 cursor-pointer text-gray-500 bg-white rounded-md border border-blueGray-200"
                            placeholder="Add a description..."
                            rows="10"
                            value={formData.professionalSummary}
                            onChange={(e) =>
                                setFormData({ ...formData, professionalSummary: e.target.value })
                            }
                        ></textarea>
                    </div>
                </div>
                
                

                
                
            </div>
            
        </form>
    </div>
      
);
}

export default Step0;
