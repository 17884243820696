import React from 'react';
import "assets/styles/inputCvFile.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCloudArrowUp, faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {uuid} from 'uuidv4';
import aciosClient from "api/axiosClient"
import {addCv} from 'redux/reducer/cvListReducer';
import {setCvId} from 'redux/reducer/searchReducer';
import {setJobId} from 'redux/reducer/searchReducer';
import {setJobTitle} from 'redux/reducer/searchReducer';
import {addJob} from 'redux/reducer/jobListReducer';
import {addJobOptions} from 'redux/reducer/jobOptionsReducer';
import {setJobRubricationData} from 'redux/reducer/searchReducer';
import {showAddCv} from "redux/reducer/addCvReducer";
import UploadService from "services/CvUploadService";
import OrCreateCV from "../Sections/SearchSections/OrCreateCV";
import EditCvName from "../Sections/SearchSections/EditCvName";
import {twMerge} from "tailwind-merge";


// drag drop file component
const InputCvFile = () => {

    const {t} = useTranslation('site');


    const [cvFile, setCvFile] = React.useState(null);
    const [cvTitle, setCvTitle] = React.useState('');
    const [filename, setFilename] = React.useState('');

    const [progress, setProgress] = React.useState(0);
    const [message, setMessage] = React.useState("");
    const [messageSuccess, setMessageSuccess] = React.useState("Uploaded the file successfully");

    const [showLoader, setShowLoader] = React.useState(false);


    // drag state
    const [dragActive, setDragActive] = React.useState(false);
    const [inputFileDisabled, setInputFileDisabled] = React.useState(false);
    // ref
    const inputRef = React.useRef(null);

    const dispatch = useDispatch();


    const handleFile = (files) => {
        const file = files[0];
        if (file) {
            setCvTitle(file.name)
            getBase64(file);
            upload(file);
        }
    }

    const getBase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let result = this.result;
            const fileData = result.replace('data:application/pdf;base64,', '');
            setCvFile(fileData);
        }
        reader.onerror = function (error) {
            console.log('Error: ', error);
        }
    }

    const upload = (file) => {

        setInputFileDisabled(false);
        setProgress(0);
        setMessage("");
        setMessageSuccess("");

        UploadService.upload(file, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        }).then((response) => {
            setFilename(response.data.filename);
            setMessageSuccess(t('upload_file_successfully'));
            setInputFileDisabled(true);
        }).catch(() => {
            setProgress(0);
            setMessage(t('could_not_upload_file'));
            setCvFile(null);
            setCvTitle('');
            setFilename('');
            setInputFileDisabled(false);
        });


    };


    const uploadCvHandler = () => {
        setShowLoader(true);

        const customer_id = uuid();
        const profile_id = uuid();

        const data = {
            customer_id,
            profile_id,
            title: cvTitle,
            raw_data: cvFile,
            file_type: "Pdf"
        };
        aciosClient.post('/CVManagement/UploadCVFromFile', data).then(res => {
            const {profile_id, owner_contact_id} = res.data;
            const cv = {
                profile_id,
                customer_id,
                owner_contact_id,
                title: cvTitle
            };
            addCvToUser(owner_contact_id, profile_id)
            dispatch(addCv(cv));
            dispatch(setCvId(profile_id));
            dispatch(setJobId(''));
            dispatch(setJobTitle(''));
            dispatch(addJob([]));
            dispatch(addJobOptions([]));
            dispatch(setJobRubricationData([]));
            dispatch(showAddCv(false));
            setShowLoader(false);
            const select_cv = document.querySelector('#cv_list');
            select_cv.dispatchEvent(new Event('change', {bubbles: true}));

        }).catch(error => {
            console.log(error);
            setShowLoader(false);

        })
    }

    const addCvToUser = (owner_contact_id, profile_id) => {

        UploadService.addCvToUser(owner_contact_id, profile_id, cvTitle, filename).then((response) => {
            console.log(response);
        })
    }

    const removeFile = () => {

        UploadService.remove(filename).then((response) => {
            if (response.status === 200) {
                setProgress(0);
                setMessage("");
                setCvFile(null);
                setCvTitle('');
                setFilename('');
                setInputFileDisabled(false);
            }
        });
    }


    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files);
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    /*
    owner_contact_id: "1922f56a-a19d-474f-acdb-c52f924f7cfb"
    profile_id: "adba5254-d235-4ca3-b89b-d98caaa5c77f"
    */

    return (
        <div className="w-full">
            <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" onChange={handleChange}
                       disabled={inputFileDisabled}/>
                <label id="label-file-upload" htmlFor="input-file-upload"
                       className={twMerge(dragActive ? "drag-active cursor-pointer" : "cursor-pointer",
                           progress === 100 ? 'upload-done' : '')}>
                    <div className="cursor-pointer">
                        <FontAwesomeIcon icon={progress === 100 ? faCircleCheck : faCloudArrowUp} size="2x"
                                         className="mb-5 text-lightBlue-800"/>
                        {progress === 100 ?
                            (<div>
                                <p className="text-2xl text-lightBlue-800">{messageSuccess}</p>
                                <div className="flex gap-2 justify-center items-center mt-3">
                                    <p className="text-xs font-semibold">{cvTitle}</p>
                                    <button className="cursor-pointer  focus:outline-none" onClick={removeFile}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="text-red-900" viewBox="0 0 16 16">
                                            <path
                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                                            <path
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>)
                            : (<div>
                                    <p className="text-2xl">{t('drag_and_drop')}</p>
                                    <p className="text-lg mt-2">{t('or')}</p>
                                    <button className="upload-button "
                                            onClick={onButtonClick}>{t('browse_a_file')}</button>
                                </div>
                            )
                        }

                        <div className="relative pt-1">

                            {(progress > 0 && progress < 100) &&
                                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-lightBlue-200">
                                    <div style={{width: progress + "%"}}
                                         className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-lightBlue-500"></div>
                                </div>}
                        </div>
                        <div className="text-red-500" role="alert">
                            {message}
                        </div>
                    </div>

                </label>

                {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag}
                                    onDragOver={handleDrag} onDrop={handleDrop}></div>}

            </form>
            <div className={`flex pt-5 ${cvTitle ? 'justify-between' : 'justify-end'}`}>
                {cvTitle && <EditCvName cvTitle={cvTitle} uploadCvHandler={uploadCvHandler} setCvTitle={setCvTitle}
                                        showLoader={showLoader}/>}
                <OrCreateCV/>
            </div>

        </div>
    );
};

export default InputCvFile;
  
