import React from "react";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import { useTranslation } from 'react-i18next';
import Form from "./Form";
function CvForm() {
    const { t } = useTranslation('site');
    



    return (
        <>
            <div className="relative bg-blueGray-100">
                <AdminNavbar title="create resume" />

                <Form/>
            </div>
            <FooterAdmin />
        </>
    );
}

export default CvForm