import {PDFViewer} from "@react-pdf/renderer";
import Info from "components/Modals/Info";
import CvPdf from "components/Pdf/CvPdf";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from 'uuid';
import {setCheckedQualifications} from "../../redux/reducer/updatedCvsReducer";
import Toggle from "../Buttons/Toggle";
import Button from "../Buttons/Button";
import axiosClientNode from "../../api/axiosClientNode";

// components


export default function Table({data = [], cssClass = '', info = ""}) {

    const [showCvPdfBtn, setShowCvPdfBtn] = React.useState(false);
    const [showCvPdf, setShowCvPdf] = React.useState(false);
    const [showHideCvBtn, setShowHideCvBtn] = React.useState(false);
    const {t} = useTranslation('site');
    const dispatch = useDispatch();
    const {cvId, jobId, cvRubricationData, jobRubricationData} = useSelector(state => state.searchInput);
    const updatedCvs = useSelector(state => state.updatedCvs);
    const updatedCv = updatedCvs.find(d => d.cvId === cvId && d.jobId === jobId);
    let checkedQualifications = updatedCv ? updatedCv.checkedQualifications : [];
    const [checkedQualificationsState, setCheckedQualificationsState] = React.useState(checkedQualifications);

    const checkQualificationHandler = (e, category_id, category_name, sub_category_id, sub_category_name, ranking_score) => {
        if (e.target.checked) {
            setCheckedQualificationsState([...checkedQualificationsState, {
                category_id,
                category_name,
                sub_category_id,
                sub_category_name,
                ranking_score,
                checked: true
            }]);
        } else {
            setCheckedQualificationsState(checkedQualificationsState.filter(d => {
                if (d.category_id === category_id) {
                    return d.sub_category_name !== sub_category_name;
                }
                return true;
            }));
        }

    }
    // React.useEffect(() => {
    //   const updateCvBtn = document.querySelector('#updateCvBtn');
    //   updateCvBtn.dispatchEvent(new Event('click', { bubbles: true }));
    // }, []);
    let generatedCv = JSON.parse(localStorage.getItem('generatedCv'));
    const updateCvHandler = (e) => {
        //update generated cv

        if (generatedCv) {

            if (checkedQualificationsState.length > 0) {
                checkedQualificationsState.map(elm => {
                    if (elm.category_id === 4000) {
                        const oldOfficialTitles = generatedCv.officialTitles;
                        const newOfficialTitle = {
                            id: uuid(),
                            createdAt: Date.now(),
                            officialTitle: elm.sub_category_name,
                            school: "",
                            year: ""
                        };
                        const updatedSocialLinks = [...oldOfficialTitles, newOfficialTitle];
                        generatedCv.officialTitles = updatedSocialLinks;
                    } else if (elm.category_id === 9000) {
                        const oldLanguages = generatedCv.languages;
                        const newLanguage = {
                            id: uuid(),
                            createdAt: Date.now(),
                            language: elm.sub_category_name,
                            level: "",
                            isNative: false
                        };

                        const updatedLanguages = [...oldLanguages, newLanguage];
                        generatedCv.languages = updatedLanguages;
                    } else {
                        const oldSkills = generatedCv.skills;
                        const newSkill = {
                            id: uuid(),
                            createdAt: Date.now(),
                            skillName: elm.sub_category_name,
                            rating: 3,
                        };

                        const updatedSkills = [...oldSkills, newSkill];
                        generatedCv.skills = updatedSkills;
                    }
                });


                localStorage.setItem('generatedCv', JSON.stringify(generatedCv));
                setShowCvPdfBtn(true);
            }


        }

        const newCvRubrications = [...cvRubricationData, ...checkedQualificationsState];


        const notCheckedQualifications = data.filter(elm => {
            const item = checkedQualificationsState.find(c => c.category_id === elm.category_id && c.sub_category_id === elm.sub_category_id);
            if (item || elm.category_id === 9000 || elm.category_id === 9300) {
                return false;
            }
            return true;
        });

        const plusText = notCheckedQualifications.reduce((prev, curr) => {
            const item = jobRubricationData.find(cv => cv.sub_category_id === curr.sub_category_id)
            if (prev === '') {
                return item.sub_category_name;
            }
            return prev + ', ' + item.sub_category_name;

        }, "");

        axiosClientNode.post('/job/checkQualifications',{
            cvId,
            jobId,
            checkedQualifications:checkedQualificationsState,
            cvRubrications: newCvRubrications,
            plusText
        }).then((res)=>{
            console.log(res.data)
        })

        dispatch(setCheckedQualifications(cvId, jobId, checkedQualificationsState, newCvRubrications, plusText));

    }

    const previewCv = () => {

        setShowCvPdf(true);
        setShowCvPdfBtn(false);
        setShowHideCvBtn(true);
    }
    const hideCv = () => {
        setShowCvPdf(false);
        setShowCvPdfBtn(true);
        setShowHideCvBtn(false);
    }

    const [isOpen, setIsOpen] = useState(true)


    return (
        <>
            <div className={cssClass}>
                <div
                    className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-blue-50 rounded-lg p-4 shadow h-full"
                >
                    <div className="flex justify-between gap-2 items-center">
                        <div className="relative w-full flex-grow flex-1 flex gap-2 items-center">

                            <h2 className="text-blueGray-700 text-lg font-semibold">
                                {t('Add_to_CV_possible')}
                            </h2>
                            {info && <Info cssClasses="absolute right-2" msg={info}/>}
                        </div>
                        <button onClick={() => setIsOpen(!isOpen)} className="focus:outline-none"><i
                            className={`fa text-xl ${isOpen ? "fa-chevron-down" : "fa-chevron-up"}`}></i></button>

                    </div>


                    {isOpen && <div className="block w-full overflow-y-auto max-h-438-px lg:scrollable-div">
                        {/* Projects table */}
                        <p className="px-4 py-2">Missing QUALIFICATIONS</p>
                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>
                            <tr>
                                <th
                                    className="align-middle px-2 lg:px-6 py-2 text-xs uppercase whitespace-nowrap font-semibold text-left"
                                >
                                    {t('CATEGORIES_Menu')}
                                </th>
                                <th
                                    className="align-middle px-2 lg:px-6 py-2 text-xs uppercase whitespace-nowrap font-semibold text-left"
                                >
                                    {t('QUALIFICATIONS_Menu')}
                                </th>
                                <th
                                    className="align-middle px-2 lg:px-6 py-2 text-xs uppercase whitespace-nowrap font-semibold text-left"
                                >
                                    {t('ADD_TO_CV_Menu')}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((d, index) => {
                                    const {
                                        ranking_score,
                                        category_name,
                                        sub_category_name,
                                        category_id,
                                        sub_category_id
                                    } = d;
                                    const checkedItem = checkedQualificationsState.find(c => c.sub_category_name === sub_category_name && c.category_id === category_id);
                                    const checked = !!checkedItem;
                                    return (
                                        <tr key={index}>
                                            <td className="border-t-0 px-2 lg:px-6 py-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
                                                {category_name}
                                            </td>
                                            <td className="border-t-0 px-2 lg:px-6 py-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
                                                {sub_category_name}
                                            </td>
                                            <td className="border-t-0 px-2 lg:px-6 py-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
                                                <Toggle checked={checked} id={index} className="justify-end"
                                                        onChange={(e) =>
                                                            checkQualificationHandler(
                                                                e,
                                                                category_id,
                                                                category_name,
                                                                sub_category_id,
                                                                sub_category_name,
                                                                ranking_score
                                                            )
                                                        }/>
                                            </td>
                                        </tr>
                                    );
                                }
                            )}


                            </tbody>
                        </table>

                    </div>}
                </div>
                {isOpen && <>
                    <Button onClick={updateCvHandler} id="updateCvBtn">{t('APPLY_Btn')}</Button>

                    {showCvPdfBtn && <Button id="updateCvBtn"
                                             onClick={previewCv}>Preview CV</Button>}
                    {showHideCvBtn && <Button id="updateCvBtn"
                                              onClick={hideCv}>HIDE CV</Button>}
                </>}
            </div>
            {showCvPdf && isOpen && <div
                className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white "
            >
                <div className="w-full lg:w-12/12">
                    <PDFViewer style={{width: "100%", height: 1000}}>
                        <CvPdf formData={generatedCv}/>
                    </PDFViewer>
                </div>
            </div>}
        </>
    );
}

