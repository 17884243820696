import React from "react";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import { useSelector } from "react-redux";
import Search from "components/Sections/Search";
import { useTranslation } from 'react-i18next';
import JobApply from "components/Sections/JobApply"

const MatchingJobs = () => {
    const { t } = useTranslation('site');
    const { cvId, showLoader, jobTitle } = useSelector(state => state.searchInput);
    

    const [showJobApply, setShowJobApply] = React.useState(false);
    
    return (
        <div className="min-h-screen flex flex-col justify-between">
            <div className="relative bg-blueGray-100 flex-grow">
                <AdminNavbar title={t('Matchin_Jobs_Header')} />
                <Search showJobApply={showJobApply} setShowJobApply={setShowJobApply}/>
                {showJobApply && <JobApply />}
            </div>
            <FooterAdmin />
        </div>
    );
}

export default MatchingJobs