import {twMerge} from "tailwind-merge";

const Button = ({children, onClick, id, disabled, className, back, next, right, withIcon,type}) => {
    const nextBtn = <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="absolute right-1 top-1/2 h-5 -translate-y-1/2 transform" viewBox="0 0 16 16"><path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"></path></svg>
    const backBtn = <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" className="svg-inline--fa fa-arrow-left absolute left-2 top-1/2 h-5 -translate-y-1/2 transform" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"></path></svg>
    const isDisabled = 'cursor-not-allowed bg-gray-200 border-gray-400 text-gray-400 shadow-none hover:shadow-none hover:bg-gray-200';
    let classes = " border text-white relative items-center text-center font-semibold uppercase text-sm py-1 px-8 rounded-full shadow-sm hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 bg-lightBlue-800 hover:bg-lightBlue-900";

    if (back) {
        classes = "text-gray-600 relative items-center text-center font-semibold uppercase text-sm py-1 px-8 rounded-full shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 border border-gray-600";
    }
    if (next) {
        classes = "bg-green-600 border-green-600 border text-white relative items-center text-center font-semibold uppercase text-sm py-1 px-8 rounded-full shadow-sm hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 hover:bg-green-700";
    }
    return (
        <button type={type} id={id} disabled={disabled} className={twMerge('cursor-pointer w-min whitespace-nowrap',classes, className, right ? 'ml-auto' : '', disabled && isDisabled)}
                onClick={(e) => onClick(e)}
        >
            {withIcon && back && backBtn}
            {children}
            {withIcon && next && nextBtn}
        </button>
    );
};

export default Button;
