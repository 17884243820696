import React from "react";

// components

import CardStats from "components/Cards/CardStats.js";
import {useTranslation} from "react-i18next";
import Info from "components/Modals/Info";

export default function HeaderStats({data}) {
    const {t} = useTranslation('site');
    return (
        <>
            {/* Header */}
            <div className="relative container mx-auto flex justify-between py-10">

                <div className="w-full lg:w-6/12 xl:w-3/12">
                    <CardStats
                        statSubtitle=""
                        statTitle={data[0]}
                        statArrow=""
                        statPercent=""
                        statPercentColor="text-emerald-500"
                        statDescripiron={t('cv_job_mapping')}
                        statIconColor="bg-red-500"
                        containerStyle={'bg-blue-50 rounded'}
                    />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12">
                    <CardStats
                        statSubtitle=""
                        statTitle={data[1]}
                        statArrow=""
                        statPercent=""
                        statPercentColor="text-red-500"
                        statDescripiron={t('Completed_CV_mapping')}
                        statIconColor="bg-orange-500"
                        containerStyle={'bg-blue-50 rounded'}
                    />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12">
                    <CardStats
                        statSubtitle=""
                        statTitle={data[2]}
                        statArrow=""
                        statPercent=""
                        statPercentColor="text-orange-500"
                        statDescripiron={t('Boosted_by_training')}
                        statIconColor="bg-pink-500"
                        containerStyle={'bg-blue-50 rounded'}
                    />
                </div>
                <Info cssClasses="absolute right-6 top-0" msg="header_stats_info"/>
            </div>
        </>
    );
}
