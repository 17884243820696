import React from "react";

const FooterAdmin = () => {
    return (
        <>
            <footer className="bg-gray-100 text-gray-800 text-xs font-bold p-4">
                <div className="container mx-auto">
                    <div className="text-center">
                        <p>&copy; {new Date().getFullYear()} <a
                            href="https://dynaskills-dynajobs.com/"
                            target="_blank"
                            rel="noreferrer"
                            className="text-gray-700 hover:text-gray-900 delay-150 duration-300 ease-in-out"
                        >
                            dynaskills®
                        </a>. All rights reserved.</p>

                    </div>
                </div>
            </footer>
        </>
    );
}
export default FooterAdmin;