import React from 'react';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBuilding, faUserTie} from '@fortawesome/free-solid-svg-icons'
import * as L from "leaflet";
import {useTranslation} from 'react-i18next';
import Info from 'components/Modals/Info';


function MapChart({data = [], header, subheader, cssClass = "w-full", showButtons = false, shadow = true, info = ""}) {
    const {t} = useTranslation('site');

    const {jobId} = useSelector(state => state.searchInput);


    let firstPosition = [46.94809, 7.44744]

    const markers = data && data.length > 0 ? data.filter(j => j.position).map((j, index) => {

        const {title, target_profile_id, company, city, position} = j;
        const {latitude, longitude} = position;
        const {name} = company;
        if (index === 0) {
            firstPosition = [latitude, longitude]
        }
        return {
            title,
            target_profile_id,
            city,
            company: name,
            coordinates: [latitude, longitude]
        };


    }) : [];

    //
    // const LeafIcon = L.Icon.extend({
    //     options: {}
    // });

    // const greenIcon = new LeafIcon({
    //     iconUrl:
    //         "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png"
    // });

    // const blueIcon = new LeafIcon({
    //     iconUrl:
    //         "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png"
    // });

    const blueIcon = L.divIcon({
        className: 'custom-icon-class',
        html: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="text-lightBlue-800" viewBox="0 0 16 16"><path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>'
    });

    const greenIcon = L.divIcon({
        className: 'custom-icon-class',
        html: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="text-green-600" viewBox="0 0 16 16"><path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/></svg>'
    });


    return (
        <div className={cssClass}>
            <div
                className={`relative flex flex-col w-full ${shadow ? 'shadow-lg' : ''} gap-y-6`}>
                <div className="flex flex-wrap items-center">
                    <div className="relative w-full bg-blue-50 rounded-lg p-4 shadow flex items-center">
                        {subheader && <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                            {subheader}
                        </h6>}
                        {header && <h2 className="text-blueGray-700 text-xl font-semibold">
                            {header}
                        </h2>}

                        {info && <Info cssClasses="absolute right-2" msg={info}/>}
                    </div>

                    {showButtons && <div className="flex ">
                        <div className="flex flex-col px-2">
                            <button
                                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-2 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5">{t('cv_job_mapping')}</button>
                        </div>
                        <div className="flex flex-col px-2">
                            <Link
                                className="bg-lightBlue-500 text-white text-center active:bg-lightBlue-600 font-bold uppercase text-sm px-2 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5"
                                to="/boost-training">{t('BOOST_BY_TRAINING_Btn')}</Link>
                        </div>
                        <div className="flex flex-col px-2">
                            <Link
                                className="bg-lightBlue-500 text-white text-center active:bg-lightBlue-600 font-bold uppercase text-sm px-2 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5"
                                to="/boost-career"> {t('BOOST_CAREER_Btn')}</Link>
                        </div>
                    </div>}
                </div>
                <div className="flex-auto rounded-lg overflow-hidden">
                    <div className="relative">
                        {/* Chart */}
                        <MapContainer center={firstPosition} zoom={5} scrollWheelZoom={true}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {markers.length > 0 && markers.map(({title, target_profile_id, company, coordinates}) => {
                                const icon = target_profile_id === jobId ? blueIcon : greenIcon

                                return (
                                    <Marker position={coordinates} key={target_profile_id} icon={icon}>
                                        <Popup>
                                            <FontAwesomeIcon icon={faBuilding}/><strong
                                            style={{marginLeft: "10px", fontSize: "14px"}}>{company}</strong><br/>
                                            <FontAwesomeIcon icon={faUserTie}/><span
                                            style={{marginLeft: "10px", fontSize: "11px"}}>{title}</span>
                                        </Popup>
                                    </Marker>
                                )
                            })}

                        </MapContainer>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MapChart;