import { useHistory} from "react-router-dom";
import FooterAdmin from "components/Footers/FooterAdmin";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import { useTranslation } from "react-i18next";
import CvHealthButtons from "./CvHealthButtons";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Buttons/Button";


const CvHealthRestrictions = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation('site');
    const changeLanguageHandler = (e) =>
    {
        i18n.changeLanguage(e.target.value)
    }
    const [link, setLink] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [action, setAction]= useState('');
    const [goToButton, setGoToButton] = useState('');
    const [enableButtons, setEnableButtons] = useState(true);


    return (
        <>
            <div className="relative bg-blueGray-100">
            <AdminNavbar title="create resume" />
                <div className=" md:pt-28 pt-12">
                    <div className="container mx-auto">
                        <div className="flex justify-between items-center">
                            <Button back withIcon onClick={()=> {
                                if(!enableButtons) {
                                    setLink('/matching-jobs')
                                    setGoToButton(<button className="{`w-full   border-2 border-solid border-blue-600 bg-white text-center text-blue-600 py-2`}" onClick={()=> setAction('exit')}>
                                        <span>{t('Back to select jobs')}</span>
                                    </button>)
                                    setShowModal(true);
                                } else {
                                    history.push('/matching-jobs')
                                }

                            }}>{t('back')}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <CvHealthButtons link={link} setLink={setLink} setAction={setAction} action={action} setShowModal={setShowModal} showModal={showModal} goToButton={goToButton} setGoToButton={setGoToButton} enableButtons={enableButtons} setEnableButtons={setEnableButtons} />
            
            <FooterAdmin />
        </>
    )
}

export default CvHealthRestrictions;