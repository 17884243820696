import axiosClient from "api/axiosClient";
import Info from "components/Modals/Info";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {addJob} from "redux/reducer/jobListReducer";
import {addJobOptions} from "redux/reducer/jobOptionsReducer";
import {setShowLoader} from "redux/reducer/searchReducer";
import {setJobId} from "redux/reducer/searchReducer";
import {setJobRubricationData} from "redux/reducer/searchReducer";
import {setTrainingRubricationData} from "redux/reducer/searchReducer";
import {setTrainingId} from "redux/reducer/searchReducer";
import {setJobTitle} from "redux/reducer/searchReducer";
import {setCvRubricationData} from "redux/reducer/searchReducer";
import translateRubrications from "utils/translateRubrications";
import axiosClientNode from "../../api/axiosClientNode";


const ActualQualifications = ({canUpdate}) => {
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation('site');
    const lang = i18n.language.substring(0, 2);
    let {resultNumber, cvRubricationData, cvId, jobId} = useSelector(state => state.searchInput);

    const [cvRubricationsState, setCvRubricationsState] = useState(cvRubricationData)


    const translatedCvRubrications = translateRubrications(cvRubricationsState, lang);
    const sortedCvRubrications = translatedCvRubrications.sort((a, b) => b.ranking_score - a.ranking_score);

    const cvQualifications = resultNumber > 0 ? sortedCvRubrications.slice(0, 10) : sortedCvRubrications;

    const handleSelectQualification = (qualification, checked) => {
        const updatedQualifications = [{
            ...qualification,
            checked
        }, ...cvRubricationsState.filter(item => item.sub_category_id !== qualification.sub_category_id)];
        setCvRubricationsState(updatedQualifications);
        // localStorage.setItem('cv_rubrications_'+cvId, JSON.stringify(updatedQualifications))
    }

    const updateCv = (rubrication_items) => {
        dispatch(setShowLoader(true));
        const body = {
            rubrication_items,
        }
        axiosClientNode.post(`/cvs/${cvId}/update`, body).then(res => {
            if (res.status === 200) {
                dispatch(setShowLoader(false));
            }
        })
    }

    const handleSubmit = () => {
        const plusTextCv = cvQualifications.filter(el=>el.checked).map(el=>el.sub_category_name).join(', ');
        updateCv(cvRubricationsState)
        dispatch(setCvRubricationData(cvRubricationsState));
        dispatch(setJobId(''));
        dispatch(setJobTitle(''));
        dispatch(addJob([]));
        dispatch(addJobOptions([]));
        dispatch(setJobRubricationData([]));
        dispatch(setTrainingId(''));
        dispatch(setTrainingRubricationData([]));
    }

    const [isOpen, setIsOpen] = useState(true);


    return (
        <div className="relative bg-gray-50 rounded-xl px-4 py-5 shadow-md flex flex-col gap-y-6">


            <div className="w-full bg-blue-50 rounded-lg p-4 shadow relative flex items-center">
                <i className="fa fa-user-check"></i>
                <h2 className="text-blueGray-700 text-xl font-semibold">
                    {t('Actual QUALIFICATIONS')}
                </h2>
                <Info cssClasses="absolute right-2" msg={t('Actual QUALIFICATIONS info')}/>
            </div>

            <div className="w-full bg-blue-50 rounded-lg p-4 shadow">
                <div>
                    <div className="flex justify-between gap-2 items-center">
                        <div className="relative w-full flex-grow flex-1 flex gap-2 items-center">

                            <h2 className="text-blueGray-700 text-lg font-semibold">
                                {t('QUALIFICATIONS for Job Mapping')}
                            </h2>
                            <Info cssClasses="absolute right-2" msg={t('QUALIFICATIONS for Job Mapping info')}/>
                        </div>
                        <button onClick={() => setIsOpen(!isOpen)} className="focus:outline-none"><i
                            className={`fa text-xl ${isOpen ? "fa-chevron-down" : "fa-chevron-up"}`}></i></button>

                    </div>
                </div>
                {isOpen && <div className="w-full relative rounded py-4">
                    <div className="flex flex-col">
                        <div>
                            {canUpdate && <div className="px-4 flex gap-2 items-center">
                                <Info hoverLeft={true} msg={t('Use to map Jobs info')}/>
                                <i className="fa fa-check text-green-500 text-lg"></i>
                                <p className="flex gap-2">
                                    {t('Use to map Jobs')}
                                </p>
                            </div>}
                            <div className="p-4 flex gap-2 flex-wrap">

                                {cvQualifications?.length > 0 && cvQualifications.filter(el => el.checked).map((item) => (
                                    <div
                                        className="flex gap-2 bg-indigo-200 px-4 py-1 rounded-full items-center text-sm font-semibold text-lightBlue-800"
                                        key={item.sub_category_id}>
                                        {item.sub_category_name}
                                        {canUpdate && <button
                                            className="text-sm text-red-700 hover:text-red-900 pl-2 focus:outline-none delay-150 duration-300 ease-in-out"
                                            title={t('delete from qualifications list')}
                                            onClick={() => handleSelectQualification(item, false)}><i
                                            className="fa fa-times"></i></button>}
                                    </div>))}

                            </div>
                        </div>


                        {canUpdate && <div className="px-4 flex gap-2 items-center">
                            <Info hoverLeft={true} msg={t('Diselect before map Jobs info')}/>
                            <i className="fa fa-times text-red-500 text-lg"></i>
                            <p className="flex gap-2">
                                {t('Diselect before map Jobs')}

                            </p>
                        </div>}
                        <div className="p-4 flex gap-2 flex-wrap">
                            {cvQualifications?.length > 0 && cvQualifications.filter(el => !el.checked).map((item) => (
                                <div
                                    className="flex gap-2 bg-red-200 px-4 py-1 rounded-full items-center text-sm font-semibold text-red-800"
                                    key={item.sub_category_id}>
                                    {item.sub_category_name}
                                    {canUpdate && <button
                                        className="text-sm hover:text-green-700 focus:outline-none delay-150 duration-300 ease-in-out"
                                        title={t('undo')} onClick={() => handleSelectQualification(item, true)}><i
                                        className="fa fa-undo"></i></button>}
                                </div>))}

                        </div>
                        {canUpdate && <div className="flex justify-center py-4">
                            <button
                                className="bg-green-600 text-white font-semibold uppercase rounded-full text-sm hover:bg-green-700 focus:outline-none delay-150 duration-300 ease-in-out py-1 px-8"
                                onClick={handleSubmit}>{t('update cv')}</button>
                        </div>}
                    </div>

                </div>}
            </div>
        </div>
    )
}

export default ActualQualifications;