import React, {useState} from "react";
import {Link, useHistory} from 'react-router-dom';
import axios from "axios";
import base64Encoder from "utils/base64Encoder";
import {useTranslation} from "react-i18next";
import PasswordChecklist from "react-password-checklist"
import Button from "../../components/Buttons/Button";
import Toggle from "../../components/Buttons/Toggle";
import SpinLoading from "../../components/Loadings/SpinLoading";
import dynaskillsLogo from "../../assets/img/dynaskillsuprising.png";
import authImg from "../../assets/img/auth.png";


export default function Register() {
    const {t, i18n} = useTranslation('site');
    const history = useHistory()
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmationPassword, setConfirmationPassword] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [showPasswordValidation, setShowPasswordValidation] = useState(false);

    // const search = useLocation().search;
    // const company = new URLSearchParams(search).get("company");

    async function registerUser(e) {
        e.preventDefault();
        setErrorMessage("");
        setShowLoader(true);


        const authorization = base64Encoder(`${username}:${password}`);
        await axios.get(
            process.env.REACT_APP_BACKEND_DYNAJOBS + '/Test/WhoIAm', {
                headers: {
                    'Authorization': 'DYNAService ' + authorization
                }
            }).then(response => {
            register();
        }).catch(err => {
            setErrorMessage("User does not exist in Dynajobs!");
        }).finally(() => {
            setShowLoader(false);
        });


    }

    const register = async () => {
        const url = process.env.REACT_APP_BACKEND_DYNASKILLS + '/users/registerPremium';
        await axios.post(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                username,
                email,
                password,
                company: "premium +"
            }
        }).then(response => {
            const data = response.data;
            if (data.status === 'ok') {
                // history.push('/auth/login');
                setShowSuccess(true);
                setTimeout(() => {
                    history.push('/auth/login')
                }, 3000);

            }
        }).catch(err => {
            console.log(err.response);
            setErrorMessage(t(err?.response?.data?.error));
        }).finally(() => {
            setShowLoader(false);
        });
    }

    const changeLanguageHandler = (e) => {
        i18n.changeLanguage(e.target.value)
    }

    const handlePassword = (e) => {
        setShowPasswordValidation(true)
        setPassword(e.target.value);
    }

    const handleConfirmationPassword = (e) => {
        setShowPasswordValidation(true)
        setConfirmationPassword(e.target.value);
    }
    const [canSignUp, setCanSignUp] = useState(false);

    return (
        <>
            <div className="flex container mx-auto py-20 gap-x-4">
                <div className="flex  flex-grow flex-col">
                    <img src={dynaskillsLogo} alt="Ukranian Sunrise" className="ml-10"
                         style={{width: "280px", height: "auto"}}/>
                    <div className="flex justify-end items-center flex-grow">
                        <img src={authImg} alt="auth image" className="w-full max-w-580-px"/>
                    </div>
                </div>
                <div className="w-1/3 ml-auto flex-grow">
                    <div className="px-4">
                        <div className="flex items-center gap-x-2 justify-end">
                            Already have an account ? <Link to="/auth/login"><Button
                            back>{t('sign_in_btn')}</Button></Link>
                        </div>
                        <div className="py-10">
                            <h2 className="font-semibold text-4xl">Welcome to Dynaskills</h2>
                            <p className="text-gray-400">Register your account</p>
                        </div>

                        {showSuccess && <div className="bg-green-400 mb-5 px-4 text-center py-5 rounded text-white">
                            {t('create_account_success')}
                        </div>}

                        <div className="mx-auto shadow-md bg-gray-50 rounded-xl p-12 gap-4 flex flex-col relative">

                            <form className="flex flex-wrap " onSubmit={registerUser}>
                                <div className="mb-3 w-full">
                                    <label
                                        className="block capitalize text-xs font-bold mb-2"
                                        htmlFor="username"
                                    >
                                        {t('username')}
                                    </label>
                                    <input
                                        id="username"
                                        type="text"
                                        className="text-left focus:outline-none block w-full relative py-2 px-4 cursor-pointer text-gray-500 bg-white rounded-md border border-blueGray-200"
                                        onChange={(e) => setUsername(e.target.value)}
                                        value={username}
                                    />
                                </div>


                                <div className="w-full mb-3">
                                    <label
                                        className="block capitalize text-xs font-bold mb-2"
                                        htmlFor="email"
                                    >
                                        {t('email')}
                                    </label>
                                    <input
                                        id="email"
                                        type="email"
                                        className="text-left focus:outline-none block w-full relative py-2 px-4 cursor-pointer text-gray-500 bg-white rounded-md border border-blueGray-200"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </div>

                                <div className="w-full mb-3">
                                    <label
                                        className="block capitalize text-xs font-bold mb-2"
                                        htmlFor="password"
                                    >
                                        {t('password')}
                                    </label>
                                    <input
                                        id="password"
                                        type="password"
                                        className="text-left focus:outline-none block w-full relative py-2 px-4 cursor-pointer text-gray-500 bg-white rounded-md border border-blueGray-200"
                                        onChange={(e) => handlePassword(e)}
                                        onBlur={() => setShowPasswordValidation(false)}
                                        value={password}
                                    />
                                </div>
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block capitalize text-xs font-bold mb-2"
                                        htmlFor="confirmation"
                                    >
                                        {t('retype_password')}
                                    </label>
                                    <input
                                        id="confirmation"
                                        type="password"
                                        className="text-left focus:outline-none block w-full relative py-2 px-4 cursor-pointer text-gray-500 bg-white rounded-md border border-blueGray-200"
                                        onChange={(e) => handleConfirmationPassword(e)}
                                        onBlur={() => setShowPasswordValidation(false)}
                                        value={confirmationPassword}
                                    />
                                </div>

                                {showPasswordValidation &&
                                    <div className="pb-5">
                                        <PasswordChecklist
                                            rules={["specialChar", "lowercase", "number", "capital", "match"]}
                                            minLength={4}
                                            iconSize={10}
                                            value={password}
                                            valueAgain={confirmationPassword}
                                            messages={{
                                                number: t('password_number'),
                                                specialChar: t('password_specialChar'),
                                                lowercase: t('password_lowercase'),
                                                capital: t('password_capital'),
                                                match: t('password_match')
                                            }}
                                        />
                                    </div>}

                                <div className="flex items-center">
                                    <label className="inline-flex items-center cursor-pointer">
                                        <Toggle id="customCheckLogin" checked={canSignUp}
                                                onChange={(e) => setCanSignUp(e.target.checked)}/>
                                        <span className="ml-2 text-sm font-semibold blueTextDark">
                                        <a
                                            className="font-bold text-lightBlue-800 underline"
                                            href="https://dynaskills-dynajobs.com/privacy-policy"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                          {t('i_agree_with_the_privacy_policy')}

                                        </a>
                                      </span>
                                    </label>
                                </div>

                                <div className="text-center mt-6  w-full ">

                                    <Button type="submit" className="flex gap-x-2" disabled={!canSignUp}
                                            right>{t('create_account')}{showLoader &&
                                        <SpinLoading color="white" insideBtn/>}</Button>
                                    {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
