import React from "react";
import {BlobProvider, PDFDownloadLink, PDFViewer} from '@react-pdf/renderer';
import CvPdf from "components/Pdf/CvPdf";
import {v4 as uuid} from 'uuid';
import aciosClient from "../../../api/axiosClient"
import {useDispatch} from "react-redux";
import {addCv} from "redux/reducer/cvListReducer";
import {setCvId} from "redux/reducer/searchReducer";
import {setJobId} from "redux/reducer/searchReducer";
import {setJobRubricationData} from "redux/reducer/searchReducer";
import {useTranslation} from "react-i18next";
import UploadService from "services/CvUploadService";
import {setJobTitle} from "redux/reducer/searchReducer";
import {addJobOptions} from "redux/reducer/jobOptionsReducer";
import {useHistory} from "react-router-dom";
import {showAddCv} from "redux/reducer/addCvReducer";


function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}


function Step5({formData, setFormData}) {
    const {t} = useTranslation('site');

    const dispatch = useDispatch();
    const history = useHistory();

    const document = <CvPdf formData={formData}/>;


    const uploadCv = async (title, blob) => {
        const cvTitle = title ? title : 'my-cv';
        let filename = "";
        UploadService.upload(blob).then((response) => {
            filename = response.data.filename;
        })

        const file = await blobToBase64(blob);
        const fileData = file.replace('data:application/pdf;base64,', '');
        const customer_id = uuid();
        const profile_id = uuid();

        const data = {
            customer_id,
            profile_id,
            title: cvTitle,
            raw_data: fileData,
            file_type: "Pdf"
        };
        aciosClient.post('/CVManagement/UploadCVFromFile', data).then(res => {
            const {profile_id, owner_contact_id} = res.data;
            const cv = {
                profile_id,
                customer_id,
                owner_contact_id,
                title: cvTitle,
            };
            UploadService.addCvToUser(owner_contact_id, profile_id, cvTitle, filename);
            dispatch(addCv(cv));
            dispatch(setCvId(profile_id));
            dispatch(setJobId(''));
            dispatch(setJobTitle(''));
            dispatch(addJobOptions([]));
            dispatch(setJobRubricationData([]));
            dispatch(showAddCv(false));

            localStorage.setItem('generatedCv', JSON.stringify(formData));
            history.push("/matching-jobs");

        }).catch(error => {
            console.log(error);
        });
    }


    return (
        <div className="flex-auto px-4 lg:px-10 py-10 ">
            <div className="flex flex-wrap ">
                <div className="w-full lg:w-12/12  py-10">
                    <BlobProvider document={document}>
                        {({blob, url, loading, error}) => {
                            if (loading) return 'loading...';
                            return (
                                <button
                                    className="block text-white font-bold uppercase text-sm px-6 py-3 rounded shadow mx-auto hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 "
                                    style={{background: '#10B981'}}
                                    type="button"
                                    onClick={() => uploadCv(formData.cvTitle, blob)}
                                >
                                    {t('start_trial_cv')}
                                </button>
                            );
                        }}
                    </BlobProvider>

                </div>
                <div className="w-full lg:w-12/12  pb-10">
                    <h2
                        className=" text-center text-4xl"
                    >
                        {t('Here_is_your_resume')}
                    </h2>
                </div>

                <div className="w-full lg:w-12/12  mb-5">
                    <div className="relative w-full flex">
                        <PDFDownloadLink
                            className="ml-auto cursor-pointer border text-lightBlue-800 relative items-center text-center font-semibold uppercase text-sm py-1 px-8 rounded-full shadow-sm hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 bg-white border-lightBlue-800 hover:bg-white block"
                            document={document} fileName={formData.cvTitle ? formData.cvTitle + '.pdf' : `my-cv.pdf`}>
                            {
                                ({blob, url, loading, error}) => {
                                    return loading ? t('loading_document') : t('download_now');
                                }
                            }
                        </PDFDownloadLink>
                    </div>
                </div>
                <div className="w-full lg:w-12/12">
                    <PDFViewer style={{width: "100%", height: 1000}}>
                        {document}
                    </PDFViewer>ddd
                </div>
            </div>
        </div>
    );
}

export default Step5;
