import React, {useEffect, useState} from 'react';
import axiosClient from "api/axiosClient";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {addJob} from "redux/reducer/jobListReducer";
import {addJobOptions} from "redux/reducer/jobOptionsReducer";
import {setCvRubricationData} from "redux/reducer/searchReducer";
import {setJobTitle} from "redux/reducer/searchReducer";
import {setShowLoader} from "redux/reducer/searchReducer";
import {setTrainingRubricationData} from "redux/reducer/searchReducer";
import {setJobRubricationData} from "redux/reducer/searchReducer";
import {setTrainingId} from "redux/reducer/searchReducer";
import {setJobId} from "redux/reducer/searchReducer";
import {setCvId} from "redux/reducer/searchReducer";
import {showAddCv} from "redux/reducer/addCvReducer";
import Info from 'components/Modals/Info';
import translateRubrications from 'utils/translateRubrications';
import Dropdown from "../../Inputs/Dropdown";

import axiosClientNode from "../../../api/axiosClientNode";

const SelectCv = () => {
    const {t, i18n} = useTranslation('site');
    const lang = i18n.language.substring(0, 2);
    const [selectedCv, setSelectedCv] = useState(false);
    const [isFetchingCvRubrications, setIsFetchingCvRubrications] = useState(false);
    const dispatch = useDispatch();
    let {
        cvId,
        resultNumber,
        cvRubricationData
    } = useSelector(state => state.searchInput, shallowEqual);
    const cvList = useSelector(state => state.cvList, shallowEqual);
    const foundCV = cvList.find((cv) => {
        return cv.profile_id === cvId;
    });


    const updateCv = (cvId, plusTextCv) => {
        const body = {
            profile_id: cvId,
            plus_text: plusTextCv
        }
        axiosClient.post(`/CVManagement/UpdateCV`, body).then()
    }

    const getCvRubrication = (cvId) => {
        setIsFetchingCvRubrications(true);
        dispatch(setShowLoader(true));

        axiosClientNode.get(`/cvs/${cvId}`).then(res => {
            const { rubrication_items } = res.data;
            dispatch(setCvRubricationData(rubrication_items));
            dispatch(setShowLoader(false));


            // const translatedCvRubrications = translateRubrications(rubrication_items,lang);
            // const sortedCvRubrications = rubrication_items.sort((a, b) => b.ranking_score - a.ranking_score);

            // const cvQualifications = resultNumber > 0 ? sortedCvRubrications.slice(0, 10) : sortedCvRubrications;
            // const plusTextCv = cvQualifications.map(el=>el.sub_category_name).join(', ')
            // updateCv(cvId, plusTextCv)
            setIsFetchingCvRubrications(false);

        })
        .catch(err => {
            dispatch(setCvRubricationData([]));
            dispatch(setShowLoader(false));
            setIsFetchingCvRubrications(false);

        });

    }

    const [selectedCV, setSelectedCV]= useState(null)
    const selectCvHandlerValue=(cv) => {
        setSelectedCV(cv)
        const selectedCvId = cv.profile_id;
        dispatch(setCvId(selectedCvId));
        dispatch(setJobId(''));
        dispatch(setJobTitle(''));
        dispatch(addJob([]));
        dispatch(addJobOptions([]));
        dispatch(setJobRubricationData([]));
        dispatch(setTrainingId(''));
        dispatch(setTrainingRubricationData([]));
        setSelectedCv(true)

        if (selectedCvId) {
            getCvRubrication(selectedCvId);
        }
    }
    useEffect(() => {
        if (cvId && cvRubricationData.length === 0 && !selectedCv && !isFetchingCvRubrications) {
            console.log('test')
            getCvRubrication(cvId);
            dispatch(setJobId(''));
            dispatch(setJobTitle(''));
            dispatch(addJob([]));
            dispatch(addJobOptions([]));
            dispatch(setJobRubricationData([]));
            dispatch(setTrainingId(''));
            dispatch(setTrainingRubricationData([]));
        }
    }, [cvId, cvRubricationData, selectedCv, isFetchingCvRubrications]);

    return (
        <>
            <div className="w-full bg-blue-50 rounded-lg p-4 shadow">
                <Info cssClasses="absolute right-6 top-4" msg="cv_title_info"/>
                <div className="py-2 flex justify-between items-center">
                    <div><label htmlFor="cv_list" className="font-semibold">{t('Select_a_cv')}</label> {t('or')}</div>
                    <button
                        className="block text-center bg-lightBlue-800 shadow-md text-white active:bg-lightBlue-900 font-semibold uppercase text-sm  py-1 px-8  rounded-full hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150"
                        onClick={() => dispatch(showAddCv(true))}>{t('add_cv')}</button>
                </div>
                <Dropdown list={cvList} selected={foundCV || selectedCV} setSelected={selectCvHandlerValue}
                          emptyText={t("No CV is selected")}/>
            </div>
        </>
    )
}
export default SelectCv;