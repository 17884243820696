import {TailSpin} from 'react-loader-spinner';
import {twMerge} from "tailwind-merge";

const Loading = ({width = "300", height = "300", className = "flex justify-center py-20", color = "#0284c7"}) => (
        <div className={twMerge("w-full flex gap-y-6 flex-col container mx-auto", className)}>
            {/*<TailSpin ariaLabel="loading-indicator" height={height} width={width} color={color}  />*/}
            <div className="border border-bg-gray-600 shadow rounded-md p-4  w-full mx-auto">
                <div className="animate-pulse flex space-x-4">
                    <div className="rounded-full bg-gray-400 h-12 w-12"></div>
                    <div className="flex-1 space-y-4 py-1">
                        <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-400 rounded"></div>
                            <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border border-bg-gray-600 shadow rounded-md p-4  w-full mx-auto">
                <div className="animate-pulse flex space-x-4">
                    <div className="flex-1 space-y-4 py-1">
                        <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-400 rounded"></div>
                            <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
;
export default Loading;