/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import {useEffect, useState} from "react";

import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";
import MapChart from "components/Charts/MapChart";

import {useLocation} from "react-router-dom";
import axiosClient from "api/axiosClient";

import Loading from 'components/Loadings/Loading'

import certificate from "../../assets/icons/certificate.png"
import dollar_coin from "../../assets/icons/dollar_coin.png"
import google_translate from "../../assets/icons/google_translate.png"
import historical from "../../assets/icons/historical.png"
import internet from "../../assets/icons/internet.png"
import location from "../../assets/icons/location.png"
import training from "../../assets/icons/training.png"
import webinar from "../../assets/icons/webinar.png"
import {twMerge} from "tailwind-merge";

const Training = ({id}) => {


    const [showLoader, setShowLoader] = useState(true);
    const search = useLocation().search;
    const [trainingId, setTrainingId] = useState(id);

    const [TrainingData, setTrainingData] = useState({});
    const trainingList = useSelector(state => state.trainingList, shallowEqual);
    const {t, i18n} = useTranslation('site');
    const lang = i18n.language.substring(0, 2);
    const getTrainingDetails = () => {
        setShowLoader(true)
        axiosClient.get(`/TrainingManagement/GetDetailTraining?trainingId=${trainingId}&language=${lang}&fallbackLanguage=DE`).then((response) => {

            if (response.data) {
                setTrainingData(response.data);
            }
            setShowLoader(false)
        })
    }


    useEffect(() => {
        getTrainingDetails();
    }, [search]);
    if (showLoader) return <Loading/>;
    return (
        <div className="text-blueGray-700 flex flex-col gap-y-6">
            <div>
                <h3 className="font-semibold text-sm text-blueGray-700"> {t('training_title')} </h3>
                <h1 className="font-bold text-2xl text-blueGray-700">{TrainingData?.title}</h1>
            </div>
            <div className="relative bg-blue-50 rounded-lg p-4 shadow">
                <h2 className="font-bold text-xl">{t('key_informations')}</h2>
                <ul className="grid grid-cols-4 gap-4 text-lg">
                    <li className="flex flex-col">
                        <div className="font-bold flex gap-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mortarboard" viewBox="0 0 16 16">
                                <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5ZM8 8.46 1.758 5.965 8 3.052l6.242 2.913L8 8.46Z"/>
                                <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Zm-.068 1.873.22-.748 3.496 1.311a.5.5 0 0 0 .352 0l3.496-1.311.22.748L8 12.46l-3.892-1.556Z"/>
                            </svg>
                            <span>
                                                {t('training_provider_name')}
                                            </span>
                        </div>
                        <p className="pl-6">{TrainingData?.company?.name}</p>
                    </li>
                    <li className="flex flex-col">
                        <div className="font-bold flex gap-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                <path
                                    d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                            </svg>
                            <span>
                                                {t('location')}
                                            </span>
                        </div>
                        <p className="pl-6">{TrainingData?.city}</p>
                    </li>
                    <li className="flex flex-col">
                        <div className="font-bold flex gap-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe2" viewBox="0 0 16 16">
                                <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z"/>
                            </svg>
                            <span>{t('registration')}</span>
                        </div>
                        <a href={TrainingData?.registration_url} target="_blank" className="pl-6">{t('click_here')}
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare}/></a>
                    </li>
                    <li className="flex flex-col">
                        <div className="font-bold flex gap-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-award" viewBox="0 0 16 16">
                                <path
                                    d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z"/>
                                <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
                            </svg>
                            <span>{t('certificate')}</span>
                        </div>
                        <p className="pl-6">{TrainingData?.certificate}</p>
                    </li>
                    <li className="flex flex-col">
                        <div className="font-bold flex gap-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-translate" viewBox="0 0 16 16">
                                <path
                                    d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z"/>
                                <path
                                    d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z"/>
                            </svg>
                            <span>{t('training_language')}</span>
                        </div>
                        <p className="pl-6">{TrainingData?.training_language}</p>
                    </li>
                    <li className="flex flex-col">
                        <div className="font-bold flex gap-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-hourglass-split" viewBox="0 0 16 16">
                                <path
                                    d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                            </svg>
                            <span>{t('time_expense')}</span>
                        </div>
                        <p className="pl-6">{TrainingData?.time_expense} {TrainingData?.time_unit}(s)</p>
                    </li>
                    <li className="flex flex-col">
                        <div className="font-bold flex gap-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-coin" viewBox="0 0 16 16">
                                <path
                                    d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path
                                    d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
                            </svg>
                            <span>{t('cost_expense')}</span>
                        </div>
                        <p className="pl-6">{TrainingData?.cost_expense} {TrainingData?.currency}</p>
                    </li>
                    <li className="flex flex-col">
                        <div className="font-bold flex gap-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bank" viewBox="0 0 16 16">
                                <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z"/>
                            </svg>
                            <span>{t('training_type')}</span>
                        </div>
                        <p className="pl-6">{TrainingData?.training_type} </p>
                    </li>
                </ul>
            </div>
            <div className="flex flex-col gap-y-6">

                <div
                    className="flex gap-1 flex-wrap overflow-y-auto max-h-300-px scrollable-div bg-blue-50 rounded-lg p-4 shadow ">
                    {trainingList.length > 0 && trainingList.map((training, index) => <button
                        key={training.target_profile_id}
                        onClick={() => {
                            setTrainingId(training.target_profile_id);
                            setTrainingData(training);
                        }}
                        className={twMerge('focus:outline-none bg-lightBlue-800 text-white py-1 px-8 rounded-full flex gap-x-2 items-center font-semibold uppercase text-sm border border-lightBlue-800 ', training.target_profile_id !== trainingId ? 'bg-white text-lightBlue-800  cursor-pointer' : '')}>
                        {training.title}
                    </button>)}
                </div>

                <div className="relative w-full bg-blue-50 rounded-lg p-4 shadow flex items-center"><h2
                    className="text-blueGray-700 text-xl font-semibold">{t('training_description')}</h2></div>
                <div className="scrollable-div overflow-y-auto text-blueGray-700 bg-blue-50 rounded-lg p-4 shadow ">
                    <p>
                        {TrainingData?.body}
                    </p>
                </div>
            </div>


            <div className="flex flex-col gap-y-6">


                <div className="flex gap-x-6">
                    <div className="w-full bg-blue-50 rounded-lg p-4 shadow ">
                        <h2 className="text-xl font-bold">{t('target_group')}</h2>
                        <p>
                            {TrainingData?.target_group}
                        </p>
                    </div>
                    <div className="w-full bg-blue-50 rounded-lg p-4 shadow">
                        <h2 className="text-xl font-bold">{t('requirements')}</h2>
                        <p>
                            {TrainingData?.requirements}
                        </p>
                    </div>
                </div>

                <MapChart header={t('Trainings_loaction')} data={[TrainingData]} cssClass="w-full" shadow={false}/>


            </div>
        </div>

    )
}

export default Training;