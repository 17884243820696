import Info from "components/Modals/Info";
import CvHealthSection from "./CvHealthSection";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setSection } from "redux/reducer/sectionReducer";
import { useHistory } from 'react-router-dom';
import axiosClient from "api/axiosClient";
import { useEffect, useState } from "react";
import Loading from "components/Loadings/Loading";
import UpdateProfileModal from "../../components/Modals/UpdateProfileModal";
import Switch from "../../components/Inputs/Switch";
import axiosClientNode from "../../api/axiosClientNode";
import Button from "../../components/Buttons/Button";

const JobHealthButtons = ({
                              link,
                              setLink,
                              action,
                              setAction,
                              showModal,
                              setShowModal,
                              goToButton,
                              setGoToButton,
                              enableButtons,
                              setEnableButtons
}) => {
    const { t, i18n } = useTranslation('restrictions');
    const lang = i18n.language.substring(0, 2).toUpperCase();
    const dispatch = useDispatch()
    const history = useHistory();
    const { cvId, jobId } = useSelector(state => state.searchInput, shallowEqual);

    const localStorageKey = `jobRestrictions_${cvId}`;
    const localStorageCvKey = `cvRestrictions_${cvId}`;
    // const savedAnswers = localStorage.getItem(localStorageKey) ? JSON.parse(localStorage.getItem(localStorageKey)): [];
    const [answers, setAnswers] = useState([]);

    // const savedCvAnswers = localStorage.getItem(localStorageCvKey) ? JSON.parse(localStorage.getItem(localStorageCvKey)): [];
    const [savedCvAnswers, setSavedCvAnswers] = useState([]);

    const [showLoader, setShowLoader] = useState(false);
    const [isDefault, setIsDefault] = useState(true);

    const handleSwitch = (val) => {
        if(!enableButtons) {
            setLink('/cv-health-restrictions')
            setGoToButton( <Switch label={('Switch to cv requirements')} handleSwith={()=> setAction('exit')} checked={true} />)
            setShowModal(true);
        }else {
            history.push('/cv-health-restrictions')
        }

    }

    const handleGoToJobSelection = () =>  {
        if(!enableButtons) {
            setLink('/matching-jobs')
            setGoToButton(<button
                className={`w-full   border-2 border-solid border-blue-600 bg-white text-center text-blue-600 py-2`}
                onClick={() => setAction('exit')}>
                <span>{t('Back to select jobs')}</span>
            </button>)
            setShowModal(true);
        }else {
            history.push('/matching-jobs')
        }
    }

    const data = [
        {
            sectionTitle: '1. Skills',
            restrictions: [
                { id: '08', title: '08 Endurance', info: 'Steady, Tenacious, Tenacious' },
                { id: '12', title: '12 body coordination', info: 'motor tasks' },
                { id: '16', title: '16 Manual Dexterity', info: 'Repeated Rapid Hand Motions' },
                { id: '17', title: '17 Dexterity', info: 'Deft, Precise' },
                { id: '21', title: '21 Responsiveness', info: 'Reflexive Response' },
                { id: '41', title: '41 Tactile perception', info: 'perception of stimuli via the skin' },
                { id: '44', title: '44 Reading comprehension', info: 'Understanding what is written' },
                { id: '46', title: '46 Listening comprehension', info: 'understanding spoken language' },
                { id: '47', title: '47 Oral skills', info: 'Communicate clearly, eloquently' },
                { id: '59', title: '59 Ability to remember and remember', info: 'remember, remember, attentive' },
                { id: '65', title: '65 Spatial orientation', info: 'sense of orientation, spatial awareness, situational awareness' },
                { id: '68', title: '68 Selective Attention', info: 'Hardly Distractable' },
                { id: '69', title: '69 Divided attention', info: 'multitasking, focused, alert' }
            ]
        },
        {
            sectionTitle: '2. Working style',
            restrictions: [
                { id: '81', title: '81 Persistence', info: 'Purposeful, persistent, persevering' },
                { id: '85', title: '85 willingness to cooperate', info: 'cooperative, team-oriented, helpful' },
                { id: '89', title: '89 Stress Tolerance', info: 'Resilient, Calm, Resilient' },
                { id: '91', title: '91 Self Control', info: 'Disciplined, Controlled, Reflective' },
                { id: '94', title: '94 Reliability', info: 'Reliable, punctual, trustworthy' },
                { id: '97', title: '97 Autonomy', info: 'Independent, responsible, self-determined' }
            ]
        },
        {
            sectionTitle: '3. Basic skills',
            restrictions: [
                { id: '153.1', title: '153.1 Sitting', info: 'Can sit continuously' },
                { id: '153.2', title: '153.2 Sitting, standing alternately 5', info: 'Can sit and stand alternately' },
                { id: '153.3', title: '153.3 Standing', info: 'Can stand continuously' },
                { id: '159', title: '159 Bend or turn', info: 'Bend or twist body' },
                { id: '160', title: '160 lifting, carrying and pushing', info: 'lifting, carrying, pushing weight' },
                { id: '161', title: '161 Working with your hands', info: 'Hand finished' },
                { id: '163', title: '163 Repetitive movements', info: 'Repetitive movement' },
                { id: '168', title: '168 Letter', info: 'Written communication' }
            ]

        },
        {
            sectionTitle: '4. Complex Skills',
            restrictions: [
                { id: '199', title: '199 Coordinate behavior of others', info: 'cooperation, empathy, adaptation of own behaviour' }
            ]

        },
        {
            sectionTitle: '5. Work context',
            restrictions: [
                { id: '237', title: '237 Exchange with others', info: 'Communicative, cooperative, interactive' },
                { id: '239', title: '239 Telephoning', info: 'telephone conversations' },
                { id: '265', title: '265 Distracting Noises', info: 'Exposed to Noise' },
                { id: '266.1', title: '266.1 Hot temperatures', info: 'temperature sensitivity hot' },
                { id: '266.2', title: '266.2 Cold temperatures', info: 'temperature sensitivity cold' },
                { id: '267.1', title: '267.1 Bright light', info: 'Light sensitivity bright light' },
                { id: '267.2', title: '267.2 dark light', info: 'photosensitivity dark light' },
                { id: '288', title: '288 Unstructured work', info: 'work without instructions, specifications' },
                { id: '289', title: '289 Exact work', info: 'Accurate, meticulous, precise' },
                { id: '290', title: '290 Repetitive Tasks', info: 'Repetitive, monotonous, routine' },
                { id: '291', title: '291 Automated work', info: 'work on production facilities' },
                { id: '295', title: '295 Time pressure', info: 'Hectic, stressful, demanding' },
                { id: '297', title: '297 Predetermined pace of work', info: 'fixed, constant, regulated, externally determined' },
                { id: '298', title: '298 Irregular working hours', info: 'unpredictable, changing, overtime' }
            ]
        },
        {
            sectionTitle: '6. Framework conditions',
            restrictions: [
                { id: '304', title: '304 Flexible working hours', info: 'adaptable, variable, individual' },
                { id: '308', title: '308 Support from staff', info: 'Cooperative, helpful, engaged' },
                { id: '312', title: '312 Accessible Transport Services', info: 'Accessible, Public Transport Available' },
                { id: '313', title: '313 Appropriate commute', info: 'Convenient commute to work' }
            ]
        }

    ]
    const goToSelectJobs = () => {
        if(enableButtons) {
            dispatch(setSection(1))
            history.push('/matching-jobs')
        }
    }
    const gotToJobHealthRestrictions = () => {
        if(enableButtons) {
            history.push('/cv-health-restrictions');
        }
    }

    const updateProfile = () => {
        if(answers.length > 0) {
            setShowLoader(true);
            // localStorage.setItem(localStorageKey, JSON.stringify(answers))
            axiosClientNode.post(`/cvs/${cvId}/answers/save?type=job&oppositeFileId=${jobId}`, {
                answers
            }).then(()=> {
                axiosClient.get(`/CVManagement/GetDetailCV?cvId=${cvId}&language=${lang}&fallbackLanguage=DE`).then(res=> {
                    if(res?.data?.body) {
                        const newBody = answers.map(item=> item.restriction).join(' \n')
                        const data = {
                            profile_id: cvId,
                            body: res.data.body + '\n ' +newBody
                        }
                        axiosClient.post(`/CVManagement/UpdateCV`, data).then(res => {
                            setShowLoader(false)
                            setEnableButtons(true)
                        }).catch(()=>{
                            setShowLoader(false)
                            setEnableButtons(true)
                        }).finally(()=>{
                            setShowLoader(false)
                            setEnableButtons(true)
                            if(link) {
                                if (link === '/matching-jobs') {
                                    dispatch(setSection(1))
                                }
                                history.push(link)
                            }

                        })
                    }
                })

            })
            setIsDefault(true)
        }
        

    }

    useEffect(() => {
        if(action === "update") {
            updateProfile()
            setAction('')
        }else if( action === 'exit') {
            if (link === '/matching-jobs') {
                dispatch(setSection(1))
            }
            history.push(link)
        }
    }, [action, link, history, updateProfile]);

    useEffect(() => {
        axiosClientNode.get(`/cvs/${cvId}/answers?type=job&oppositeFileId=${jobId}`).then((res)=>{
            setAnswers(res.data.savedAnswers);
            setSavedCvAnswers(res.data.savedOppositeAnswers);
            if(res.data.savedAnswers && res.data.savedAnswers.length >0 ){
                setIsDefault(false)
            }else {
                setIsDefault(true)
            }
        })
    }, []);
    return (
        <div className="bg-blueGray-100">
            <UpdateProfileModal goToButton={goToButton} showModal={showModal} text={'Would you like to save your changes before leaving? if yes, please choose Update Profile'} setShowModal={setShowModal} setAction={setAction} />
            <div className="container mx-auto py-10 ">
                <div className="flex justify-between">
                    <div className="text-blue-600 flex gap-2 items-center">
                        <i className="fa fa-plus"></i><span className="">{t('Health needs / Jobs')} </span>
                    </div>
                    <div>
                        <Switch label={'Switch to cv requirements'} handleSwith={handleSwitch} checked={true} />
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <div className="flex items-center text-2xl text-blue-600 py-10 gap-2">
                        <i className="fas fa-address-card"></i>
                        <span className=" font-bold">{t('Jobs')}</span>
                        {isDefault ? <span
                            className={"text-sm bg-amber-300 text-gray-700 text-bold rounded p-1"}>
                            {t('not updated')}
                        </span> : <span
                            className={"text-sm bg-green-300 text-gray-700 text-bold rounded p-1"}>
                            {t('updated')}
                        </span>}
                    </div>
                    <Info  msg={"candidate info"} showImage={true} prefix="job" />
                </div>
                {showLoader ? <Loading />: (<div className="w-full flex flex-col gap-2 ">
                    {data.map((section) => (
                        <CvHealthSection key={section.sectionTitle} sectionTitle={section.sectionTitle} restrictions={section.restrictions} actionName="SetJob" defaultValue={0} answers={answers} setAnswers={setAnswers} oppositeAnswers={savedCvAnswers} secondColor={"rgba(3,74,252,0.5)"} mainColor={"rgba(122,157,68,0.5)"} setEnableButtons={setEnableButtons} disabled={Boolean(jobId)}  />
                    ))}
                </div>)}
                <div className="flex justify-between mt-10">
                    <Button onClick={handleGoToJobSelection} back>{t('Back to select jobs')}</Button>
                    <Button onClick={updateProfile}>{t('Update Profile')}</Button>
                </div>
            </div>
        </div>
    )
}
export default JobHealthButtons;
