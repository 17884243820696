import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import ForgetPassword from "views/auth/ForgetPassword";
import ResetPassword from "views/auth/ResetPassword";
import FooterAdmin from "../components/Footers/FooterAdmin";
import authImg from '../assets/img/auth.png'
import dynaskillsLogo from "../assets/img/dynaskillsuprising.png";
import TranslationSwitcher from "../components/Sections/TranslationSwitcher";

export default function Auth() {
  return (
    <>
      <main>
        <section className="relative w-full h-full min-h-screen bg-gray-100 flex flex-col justify-between">
          <div className="absolute right-10 top-10 rounded-md shadow-md z-1">
            <TranslationSwitcher />
          </div>
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Route path="/auth/forget-password" exact component={ForgetPassword} />
            <Route path="/auth/reset-password" exact component={ResetPassword} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          <FooterAdmin/>
        </section>
      </main>
    </>
  );
}
