import { useState } from "react";
import { useTranslation } from "react-i18next";

const SendMessage = () => {
    const { t } = useTranslation('site');
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <div className="flex gap-14 pl-4">
                <button
                    className={`block ml-auto focus:outline-none bg-lightBlue-800 text-white py-1 px-8 rounded-full items-center font-semibold uppercase text-sm border border-lightBlue-800 `}
                    type="button"
                    onClick={() => setShowModal(true)}
                >
                    {t('ask_dynaskills')} 
                </button>
            </div>
            {showModal && (
        
                <form >
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        
                    >
                        <div className="relative w-auto my-6 mx-auto w-1/2">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="bg-lightBlue-800 text-white flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h3 className="text-2xl font-semibold">
                                {t('write_your_message_to_us')}
                            </h3>
                            <button
                                className=" ml-auto border-0 text-white float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModal(false)}
                            >
                                <span className="text-white h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                                </span>
                            </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                                <div className=" py-2">
                                    <textarea  name="message" id="message" className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full h-64"
                                    ></textarea>
                                </div>
                                
                                
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
                                
                                <button
                                className="bg-lightBlue-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 flex gap-2 items-center"
                                type="submit"
                                    >
                                <i class="fa fa-paper-plane"></i>
                                {t('submit')}
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </form>
            )}
        </>
    )
}

export default SendMessage;