import axiosClient from "api/axiosClient";
import Info from "components/Modals/Info";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {setShowLoader} from "redux/reducer/searchReducer";
import {setTrainingRubricationData} from "redux/reducer/searchReducer";
import {setTrainingId} from "redux/reducer/searchReducer";
import {addTrainingList} from "redux/reducer/trainingListReducer";
import Loading from '../../Loadings/Loading';
import Button from "../../Buttons/Button";
import Popup from "../../Modals/Popup";
import TrainingPopup from "../TrainingPopup";
import DropdownTrainings from "../../Inputs/DropdownTrainings";
import CheckJob from "./CheckJob";
import CheckTraining from "./CheckTraining";


const SelectTraining = () => {
    const {t, i18n} = useTranslation('site');
    let lang = i18n.language;
    lang = i18n.language.substring(0, 2).toUpperCase();
    const [showTrainingLoader, setShowTrainingLoader] = useState(false);

    const dispatch = useDispatch();
    let {
        cvId,
        jobId,
        trainingId
    } = useSelector(state => state.searchInput, shallowEqual);
    const trainingList = useSelector(state => state.trainingList, shallowEqual);
    const updatedCvs = useSelector(state => state.updatedCvs, shallowEqual);
    const selectTrainingHandler = (e) => {
        setSelectedTraining(e)
        const selectedTrainingId = e.target_profile_id;
        dispatch(setTrainingId(selectedTrainingId));

        getTrainingRubrication(selectedTrainingId);
    }



    const getTrainingRubrication = (trainingId) => {
        if (trainingId) {
            dispatch(setShowLoader(true));
            axiosClient.get(`/Rubrication/GetTrainingRubricationById?trainingId=${trainingId}`).then(res => {
                if (res.status === 200) {
                    const {rubrication_items} = res.data;
                    dispatch(setTrainingRubricationData(rubrication_items));
                }
                dispatch(setShowLoader(false));
            }).catch(err => dispatch(setShowLoader(false)));
        } else {
            dispatch(setTrainingRubricationData([]));
        }


    }
    const trainingMatchingHandler = (cvId, jobId) => {
        findTrainings(cvId, jobId);
    }

    const findTrainings = (cvId, jobId) => {

        dispatch(setShowLoader(true));
        setShowTrainingLoader(true)
        const updatedCv = updatedCvs.find(d => d.uniqueId === cvId + jobId);
        const plusText = updatedCv ? updatedCv.plusText : '';
        const body = {
            "ref_profile_id": "",
            "exclude_pooled_items": true,
            "selected_cv_id": cvId,
            "selected_job_id": jobId,
            "pre_selection": {
                "cost_expense": 50000,
                "time_expense": 50000,
                "training_filter": "NotFiltered",
                "affiliation": "NotFiltered",
                "plus_text": plusText
            },
            "languages": [
                "FR", "DE", "EN"
            ],
            "language": lang,
            "fallback_language": "DE"
        };
        axiosClient.post(`/Matching/GetTrainingMatchByPreselection`, body).then(res => {
            if (res.status === 200) {

                if (res.data && res.data.length) {
                    // let trainings = {
                    //     cvId,
                    //     jobId,
                    //     trainingList: []
                    // };
                    let trainings = [];
                    let firstTrainingId = '';

                    res.data.map((data, index) => {
                        const {title, company, target_profile_id, ranking_score, city, position} = data;
                        const training = {
                            title,
                            company,
                            target_profile_id,
                            ranking_score,
                            city,
                            position
                        }
                        // trainings.trainingList.push(training);
                        trainings.push(training);
                        if (index === 0) {
                            firstTrainingId = target_profile_id;
                        }
                    })
                    dispatch(addTrainingList(trainings));
                    dispatch(setTrainingId(firstTrainingId));

                    setShowTrainingLoader(false);
                    const select_training = document.querySelector('#training_list');
                    select_training.value = firstTrainingId;
                    select_training.dispatchEvent(new Event('change', {bubbles: true}));
                }
            } else {
                dispatch(setShowLoader(false));
                setShowTrainingLoader(false);
            }
        }).catch(err => {
            dispatch(setShowLoader(false));
            setShowTrainingLoader(false);
        }).finally(() => {
            dispatch(setShowLoader(false));
            setShowTrainingLoader(false);
        });
    }
    const [showTrainingId,setShowTrainingId] = useState(null)
    const showDetails = (target_profile_id) => {
        setShowTrainingId(target_profile_id);
        setOpenPopup(true)
    }
    const [openPopup, setOpenPopup] = useState(false);
    const [selectedTraining, setSelectedTraining]= useState(trainingList[0]||null)

    if (!selectedTraining && trainingList && trainingList.length)
    {
        selectTrainingHandler(trainingList[0]);
    }
    useEffect(() => {
        trainingMatchingHandler(cvId, jobId);
    }, []);
    return (
        <>
            <div className="flex ">
                <Info cssClasses="absolute right-6 top-4" msg="select_training_info"/>
                {
                    showTrainingLoader ? <Loading className="mx-auto"/> :
                        (<div className=" px-3 w-full">
                            <label htmlFor="training_list"
                                   className="font-semibold flex gap-1">{t('Choose_a_training')}{showTrainingLoader &&
                                <Loading/>}</label><br/>

                            <div className="flex flex-wrap gap-4 max-h-500-px scrollable-div overflow-x-auto scrollbox py-1 justify-center">
                                {
                                    trainingList && trainingList.length > 0 && trainingList.map((training, index) => {
                                        return (
                                            <CheckTraining key={index} training={training} selected={selectedTraining} setSelected={selectTrainingHandler} showDetails={showDetails}/>
                                        )
                                    })
                                }
                            </div>
                        </div>)
                }
            </div>
            <Popup open={openPopup} closePopup={setOpenPopup}>
                <div>
                    <TrainingPopup id={showTrainingId}></TrainingPopup>
                </div>
            </Popup>
        </>
    )
}
export default SelectTraining;