

const SHOW_ALERT = 'show_alert';



export const showAlert = (bool) => ({
    type: SHOW_ALERT,
    bool
});




const initialState = false;

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_ALERT:

            const { bool } = action
            return bool;
        default:
            return state;
            
    }
}