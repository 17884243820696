import React, {useEffect, useRef} from 'react';

const Popup = ({children, open = false, closePopup}) => {
    const popupRef = useRef(null);

    useEffect(() => {
        const closeOnOutsideClick = (e) => {
            if (popupRef.current && !popupRef.current.contains(e.target)) {
                closePopup(false);
            }
        };

        if (open) {
            document.body.style.overflow = 'hidden';
            document.addEventListener('click', closeOnOutsideClick);
        } else {
            document.body.style.overflow = 'auto';
            document.removeEventListener('click', closeOnOutsideClick);
        }

        return () => {
            document.body.style.overflow = 'auto';
            document.removeEventListener('click', closeOnOutsideClick);
        };
    }, [open, closePopup]);

    if (open) {
        return (<>
                <div className="w-full h-full fixed bg-black bg-opacity-40 top-0 left-0 py-24 px-4"
                     style={{zIndex: 10000}}>
                    <div ref={popupRef}
                        className="w-full container mx-auto h-full bg-white rounded-lg scrollable-div overflow-y-auto px-4 py-10 scrollbox relative">{children}

                        <button onClick={() => closePopup(false)} className="absolute top-4 right-4 focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                 className="bi bi-x w-6 h-6" viewBox="0 0 16 16">
                                <path
                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </>
        );
    }

    return null;
};

export default Popup;
